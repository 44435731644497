import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../shared/models/user.model';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {Router} from '@angular/router';
import {TitleService} from '../../../shared/services/common/title.service';
import {FormFlagsComponent} from '../form-flags/form-flags.component';
import {MatDialog} from '@angular/material/dialog';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {HttpFlagService} from '../../../shared/services/http/http-flag.service';
import {HttpUserService} from '../../../shared/services/http/http-user.service';
import {EditUserService} from '../../../shared/services/common/edit-user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // Active user
  currentUser: User;
  exit = false;
  links: any[];
  title: string;
  url: string;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private httpflagService: HttpFlagService,
    private httpUser: HttpUserService,
    private titleSvc: TitleService,
    private editUser: EditUserService,
    private snackSvc: SnackbarService,
    private authSvc: AuthenticationService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.links = [
          {label: 'Admin', icon: 'star', url: 'admin', hide: this.currentUser?.role < User.admin},
          {label: 'Profile', icon: 'account_circle', url: 'user/info', hide: this.currentUser?.role < User.ef},
          {label: 'Tours', icon: 'inbox', url: 'tours', hide: this.currentUser?.role < User.ef},
          {label: 'Orders', icon: 'receipt', url: 'orders', hide: this.currentUser?.role < User.ef},
          {label: 'space', hide: this.currentUser?.role < User.ef},
          {label: 'TD Optionals', icon: 'local_activity', url: 'optionals', hide: !this.currentUser?.user_settings?.edit_optionals},
          {label: 'City Free Time', icon: 'explore', url: 'freetime', hide: this.currentUser?.role < User.ef},
          {label: 'space', hide: this.currentUser?.role < User.ef},
          {label: 'Finder', icon: 'share_location', url: 'finder', hide: this.currentUser?.role < User.ef},
          {label: 'Incidents', icon: 'campaign', url: 'incidents', hide: this.currentUser?.role < User.ef},
          {label: 'space', hide: this.currentUser?.role < User.ef},

          {label: 'Home', icon: 'home', url: 'home', hide: this.currentUser?.role > User.pax_admin},
          {label: 'space', hide: this.currentUser?.role > User.pax_admin},
          {label: 'Tour Optionals', icon: 'local_activity', url: 'optional_activity', hide: this.currentUser?.role > User.pax_admin},
          {label: 'TD Appreciation', icon: 'savings', url: 'appreciation', hide: this.currentUser?.role > User.pax_admin},
          {label: 'space', hide: this.currentUser?.role > User.pax_admin},
          {label: 'Upgrade app', icon: 'workspace_premium', url: 'upgrade', hide: this.currentUser?.role > User.pax_admin},
          {label: 'Donate', icon: 'volunteer_activism', url: 'donate', hide: this.currentUser?.role > User.pax_admin},
          {label: 'space', hide: this.currentUser?.role > User.pax_admin},

          {label: 'Support', icon: 'support', url: 'support', hide: this.currentUser?.role < User.ef},
          {label: 'Logout', icon: 'logout', url: 'logout'},
        ];
      });
  }

  ngOnInit() {
    this.url = window.location.href;
    this.titleSvc.currentTitle
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(title => this.title = title);
  }

  changeUrl(url) {
    if (url === 'logout') {
      this.url = '/login';
    }
  }

  onGoHome() {
    if (this.currentUser) {
      this.router.navigate(['tours']);
    } else {
      this.router.navigate(['/']);
    }
  }

  setFlag() {
    if (this.currentUser.id) {
      const flag_url = this.router.url;
      const dialogRef = this.dialog.open(FormFlagsComponent, {
        autoFocus: true,
        data: {'user_role': this.currentUser.role, 'no_hide': false, 'url': flag_url.replace(/\//, '')}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result['reason'] !== 'close') {
          if (result['reason'] === 'hide') {
            this.currentUser.user_settings.flag_icon = false;
            this.editUser.onUserSettsChange('flag_icon');
            return;
          } else {
            const params = {
              'user_id': this.currentUser.id,
              'flag_url': flag_url,
              'problem': result['problem'],
              'solution': result['solution'],
            };
            this.httpflagService.createFlag(params)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                res => {
                  console.log(res);
                  if (res.status < 400) {
                    const flag = res.results.flag;
                    // console.log(flag);
                    this.snackSvc.openSnackBar('Thank you!');
                  } else {
                    this.snackSvc.resultsElse(res);
                  }
                },
                error => {
                  console.log(error);
                  this.snackSvc.openSnackBar('ERROR. Creating flag');
                });
          }
        }
      });
    } else {
      this.snackSvc.openSnackBar('Only members can report');
    }
  }
}
