import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {City} from '../../../../shared/models/city.model';
import {Activity} from '../../../../shared/models/activity.model';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpActivityService} from '../../../../shared/services/http/http-activity.service';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {AddActComponent} from '../../../components/activity/add-act/add-act.component';
import {OpenGalleryComponent} from '../../../../shared/components/open-gallery/open-gallery.component';
import {Comptype} from '../../../../shared/models/comptype.model';
import {Company} from '../../../../shared/models/company.model';


@Component({
  selector: 'app-adm-acts',
  templateUrl: './adm-acts.component.html',
  styleUrls: ['./adm-acts.component.scss']
})
export class AdmActsComponent implements OnInit, OnDestroy {
  currentUser: User;
  activities: Activity[] = [];
  activsFilt: Activity[] = [];
  cities: City[] = [];
  filter: string;
  sortBy: string;
  baseUrl: string;
  loading = false;
  searchText: string;
  searching = false;
  showSearch = false;
  showCias = false;

  companies: Company[] = [];
  compTypes: Comptype[];
  idxActEdit: any[] = [];

  private onDestroy$ = new Subject<boolean>();
  @ViewChildren('activity_target') searchElementActsRefs: QueryList<ElementRef>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private httpActSvc: HttpActivityService,
    private httpCitySvc: HttpCityService,
    private textTransform: TextTransformService,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxActEdit = [];
    }
  }

  ngOnInit(): void {
    this.sortBy = null;
    this.baseUrl = environment.baseUrl;
    this.compTypes = JSON.parse(localStorage.getItem('compTypes'));
    this.companies = JSON.parse(localStorage.getItem('companies'));
    const list = '.2.3.4.5.6.8.10.12.13.14.15.18.19.21.';
    this.compTypes = this.compTypes.filter(c => list.includes('.' + c.id + '.'));
    const list2 = '.1.2.3.4.5.';
    this.companies = this.companies.filter(c => list2.includes('.' + c.id + '.'));
    this.getAllActivities();
    this.getAllCities();
  }

  orderBy(txt) {
    if (this.sortBy === txt) {
      this.sortBy = null;
      this.activsFilt.sort(function (a, b) {
        return a.city.country.localeCompare(b.city.country) || a.city.name.localeCompare(b.city.name) || a.name.localeCompare(b.name);
      });
    } else {
      this.activsFilt = this.activsFilt.sort((a, b) => a[txt] < b[txt] ? 1 : a[txt] === b[txt] ? 0 : -1);
      this.sortBy = txt;
    }
  }

  filterBy(role?, value?) {
    if (this.filter === role) {
      this.filter = null;
      this.activsFilt = this.activities;
    } else {
      if (role === 'image') {
        this.activsFilt = this.activities.filter(h => !h.image);
        this.filter = role;
      } else if (role === 'companies') {
        this.activsFilt = this.activities.filter(h => !h.companies);
        this.filter = role;
      } else if (role === 'when') {
        this.activsFilt = this.activities.filter(h => h.when);
        this.filter = role;
      } else if (role === 'prene') {
        this.activsFilt = this.activities.filter(h => h.prev_city || h.next_city);
        this.filter = role;
      } else if (!isNaN(Number(+role))) {
        this.activsFilt = this.activities.filter(h => h.companies.includes(role));
        this.filter = role;
      } else if (role?.includes('type') && value) {
        this.activsFilt = this.activities.filter(h => +h.comp_type_id === +value);
        this.filter = 'type' + value;
      } else {
        this.filter = null;
        this.activsFilt = this.activities;
      }
    }
  }

  updateActivities() {
    this.activities.map(act => {
      if (act.companies) {
        act.company_ids = act.companies.replace(/^,*/, '').replace(/,*$/, '').split(',').map(i => +i);
        act.cia = '';
        if (act.company_ids.includes(1)) {
          act.cia += 'PL,';
        }
        if (act.company_ids.includes(2)) {
          act.cia += 'UB,';
        }
        if (act.company_ids.includes(3)) {
          act.cia += 'ET,';
        }
        if (act.company_ids.includes(4)) {
          act.cia += 'ADV,';
        }
        if (act.company_ids.includes(5)) {
          act.cia += 'GY,';
        }
        if (act.company_ids.includes(6)) {
          act.cia += 'GAT,';
        }
        act.cia = act.cia.replace(/^,*/, '').replace(/,*$/, '');
        if (act.cia === '') {
          act.cia = null;
        }
      }
    });
  }

  getAllActivities() {
    this.loading = true;
    this.httpActSvc.allActs()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Get all activities + trashed
            this.activities = res.results.activities;
            this.updateActivities();
            this.sortBy = null;
            this.filterBy();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting activities');
        });
  }

  getAllCities() {
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Insert all cities
            this.cities = res.results.cities;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  onEditActivity(act: Activity) {
    if (!act.deleted_at) {
      this.idxActEdit.push(act.id);
    }
  }

  onUpdateActivity(txt, activity: Activity, e) {
    if (txt !== 'restore' && txt !== 'del_image' && txt !== 'confirmed' && (activity.deleted_at || !this.idxActEdit.includes(activity.id))) {
      return;
    }
    const data = {
      'req_id': this.currentUser.id
    };
    if (txt === 'name') {
      data['name'] = activity.name;
    } else if (txt === 'restore') {
      data['restore'] = true;
      activity.loading_img = true;
    } else if (txt === 'del_image') {
      activity.loading_img = true;
      data['image'] = null;
      data['del_image'] = true;
    } else if (txt === 'image_id') {
      activity.loading_img = true;
      data['image_id'] = activity.image_id;
    } else if (txt === 'all') {
      data['city_id'] = activity.city.id;
      data['all_cities'] = activity.city.name.toLowerCase();
      data['comp_type_id'] = activity.comp_type.id;
      data['image_id'] = activity.image_id;
      data['name'] = activity.name;
      data['from'] = activity.from;
      data['to'] = activity.to;
      data['supplier'] = activity.supplier;
      data['email'] = activity.email;
      data['companies'] = activity.companies;
      data['when'] = activity.when;
      data['prev_city'] = activity.prev_city;
      data['next_city'] = activity.next_city;
      data['notes'] = activity.notes;
    } else if (e && e.value) {
      data[txt] = e.value;
    } else if (e && e.target.value) {
      data[txt] = e.target.value;
    }
    // console.log(data);
    this.httpActSvc.updateActivity(activity.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (!txt.includes('image')) {
              const removeIdx = this.idxActEdit.findIndex(i => '' + i === '' + activity.id);
              this.idxActEdit.splice(removeIdx, 1);
            }
            activity = res.results.activity;
            const idx = this.activities.findIndex(it => '' + it.id === '' + activity.id);
            this.activities[idx] = res.results.activity;
            const idx1 = this.activsFilt.findIndex(it => '' + it.id === '' + activity.id);
            this.activsFilt[idx1] = res.results.activity;
            this.filterBy();
            if (!this.sortBy) {
              this.activsFilt.sort(function (a, b) {
                return a.city.country.localeCompare(b.city.country) || a.city.name.localeCompare(b.city.name) || a.name.localeCompare(b.name);
              });
            } else {
              this.activsFilt = this.activsFilt.sort((a, b) => a[this.sortBy] < b[this.sortBy] ? 1 : a[this.sortBy] === b[this.sortBy] ? 0 : -1);
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          activity.loading_img = false;
        },
        error => {
          activity.loading_img = false;
          console.log(error);
        });
  }

  uploadActivityImage(activity: Activity, event) {
    if (activity.deleted_at) {
      this.snackSvc.openSnackBar('Hotel deleted');
      return;
    }
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      activity.loading_img = true;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 0, 800, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('activity_id', activity.id);
              formData.append('req_id', '' + this.currentUser.id);
              this.httpActSvc.uploadActImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    activity.loading_img = false;
                    if (res.status < 400) {
                      activity.image = res.results.activity.image;
                      activity.image_id = res.results.activity.image.id;
                      this.activsFilt.find(h => '' + h.id === '' + activity.id).image = activity.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    activity.loading_img = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Uploading activity image');
                  });

            }, (err: NgxPicaErrorInterface) => {
              activity.loading_img = false;
              console.log('activity image not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          activity.loading_img = false;
          console.log('activity image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  onOpenGallery(act: Activity) {
    const dialogRef = this.dialog.open(OpenGalleryComponent, {
      minWidth: '80vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: true,
      data: {'city_id': act.city_id, 'user_id': this.currentUser.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] === 'save') {
        act.image_id = result['image']['id'];
        act.image = result['image'];
        this.onUpdateActivity('image_id', act, null);
      } else {
        // console.log('Didnt ok');
      }
    });
  }

  onSelectionCiaChange(event, act: Activity) {
    act.company_ids = event.value;
    act.companies = act.company_ids.join(',') + ',';
  }

  addNewAct() {
    const dialogRef = this.dialog.open(AddActComponent, {
      autoFocus: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'city_id': result['city_id'],
          'comp_type_id': result['comp_type_id'],
          'image_id': result['image_id'],
          'name': result['name'],
          'from': result['from'],
          'to': result['to'],
          'supplier': result['supplier'],
          'email': result['email'],
          'companies': result['companies'],
          'when': result['when'],
          'prev_city': result['prev_city'],
          'next_city': result['next_city'],
          'notes': result['notes'],
        };
        // console.log(params);
        this.httpActSvc.createActivity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const act = new Activity(res.results.activity);
                const actIdx = this.activsFilt.findIndex(x => x.name === act.name);
                if (actIdx < 0) {
                  this.activsFilt.push(res.results.activity);
                } else {
                  this.snackSvc.openSnackBar('Activity already exists');
                }
                if (this.sortBy) {
                  this.orderBy(this.sortBy);
                } else {
                  this.activsFilt.sort(function (a, b) {
                    return a.city.country.localeCompare(b.city.country) || a.city.name.localeCompare(b.city.name) || a.name.localeCompare(b.name);
                  });
                }
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  onOkActivity(act_id) {
    const removeIdx = this.idxActEdit.findIndex(i => '' + i === '' + act_id);
    this.idxActEdit.splice(removeIdx, 1);
  }

  openImg(act: Activity) {
    window.open(this.baseUrl + 'storage/' + act.image.path, '_blank');
  }

  onDeleteAct(act) {
    // Show snackbar to undo restore
    const snackbarRef = this.snackSvc.openSnackBar('Force delete act', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          act.loading_img = true;
          this.httpActSvc.deleteActivity(act.id, this.currentUser.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              console.log(res);
              if (res.status < 400) {
                const idx = this.activsFilt.findIndex(it => '' + it.id === '' + act.id);
                this.activsFilt.splice(idx, 1);
              } else {
                this.snackSvc.resultsElse(res);
              }
              act.loading_img = false;
            }, error => {
              act.loading_img = false;
              this.snackSvc.openSnackBar('Error deleting activity ' + act.id);
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
