import {Component, OnDestroy, OnInit} from '@angular/core';
import {Hotel} from '../../../../../shared/models/hotel.model';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {Subject} from 'rxjs';
import {HttpHotelService} from '../../../../../shared/services/http/http-hotel.service';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';

@Component({
  selector: 'app-main-tour-hotels',
  templateUrl: './main-tour-hotels.component.html',
  styleUrls: ['./main-tour-hotels.component.scss']
})
export class MainTourHotelsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  loadingHotels = false;
  tourHotels: Hotel[] = [];

  returnUrl: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private titleSvc: TitleService,
    private versioning: VersioningService,
    private httpTour: HttpTourService,
    private httpHotel: HttpHotelService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    const toursUser = this.activeTourSvc.getToursUser();
    const tour_prodid = this.route.snapshot.params['tour-prodid'];
    if (this.activeTour.itinerary.length <= 0) {
      const tour = toursUser.find(x => '' + x.prodid === '' + tour_prodid);
      if (!toursUser || !tour) {
        this.snackSvc.openSnackBar('Error. Invalid tour');
        this.router.navigate(['tours']);
        return;
      }
    }
    this.getTourHotels(this.activeTour.tour.id);
  }

  getTourHotels(tour_id: number) {
    this.loadingHotels = true;
    this.httpHotel.getHotelsTour(tour_id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.versioning.check(res.results.version);
            this.tourHotels = res.results.hotels;
            this.tourHotels.map(h => h.rooms_some = h.rooms.some(r => r[1] > 0));
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingHotels = false;
        },
        error => {
          console.log(error);
          this.loadingHotels = false;
        });
  }

  onRefreshH() {
    this.getTourHotels(this.activeTour.tour.id);
  }

  updTourSetts(txt: string) {
    this.activeTour.tour.tour_settings.room_prefs = txt;
    this.updateTour([{'key': 'room_prefs', 'value': this.activeTour.tour.tour_settings.room_prefs}]);
  }

  OnGoHotel(h) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels', h.component_id],
      {queryParams: {returnUrl: window.location.pathname, i: h.itinerary_id}});
  }

  // TOUR ACTIONS

  updateTour(values: any) {
    this.loadingHotels = true;
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      params[i.key] = '' + i.value;
    }
    // console.log(params);
    this.httpTour.updateTour(this.activeTour.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.activeTour.tour = res.results.tour;
          this.activeTourSvc.setActiveTour(this.activeTour);
        } else {
          this.snackSvc.resultsElse(res);
        }
        this.loadingHotels = false;
      }, error => {
        console.log(error);
        this.loadingHotels = false;
        this.snackSvc.openSnackBar('ERROR. Updating tour');
      });
  }


  goBack() {
    if (this.returnUrl && this.returnUrl === window.location.pathname) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels'], {queryParams: {returnUrl: window.location.pathname}});
    } else if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
