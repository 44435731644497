import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {MatDialog} from '@angular/material/dialog';
import {AddVoucherComponent} from '../../../components/admin/add-voucher/add-voucher.component';
import {AddCreditComponent} from '../../../components/admin/add-credit/add-credit.component';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {EditUserComponent} from '../../../components/admin/edit-user/edit-user.component';
import {Tour} from '../../../../shared/models/tour.model';
import {UpdateVersionComponent} from '../../../components/update-version/update-version.component';

@Component({
  selector: 'app-adm-user',
  templateUrl: './adm-user.component.html',
  styleUrls: ['./adm-user.component.scss']
})
export class AdmUserComponent implements OnInit, OnDestroy {
  currentUser: User;
  users: User[] = [];
  usersTDs: User[] = [];
  usersFiltered: User[] = [];
  math = Math;
  role: string;
  loading: boolean;
  searchText: string;
  searching = false;
  showSearch = false;
  total: number;
  pags = 1;
  take = 200;
  skip = 0;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private httpUser: HttpUserService,
    private httpTour: HttpTourService,
    private httpOrders: HttpOrdersService,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit(): void {
    this.currentUser.view_as = null;
    this.authSvc.updateUser(this.currentUser);
    this.getAllUsers('only_tds');
  }

  getAllUsers(role?) {
    const search = role ? role : (this.searchText ? this.searchText : null);
    if (this.role === role) {
      this.role = null;
      role = null;
    } else {
      if (!role && this.role) {
        role = this.role;
      } else if (role) {
        this.role = role;
      } else {
        this.role = null;
        role = null;
      }
    }
    this.loading = true;
    this.httpUser.getAllUsers2(this.take, this.skip, search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status < 400) {
            this.users = res.results.users;
            this.usersFiltered = this.users;
            this.usersTDs = this.users.filter(u => u.role >= User.ef);
            this.total = res.results.total;
            this.pags = Math.ceil(this.total / this.take) || 1;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all users');
        });
  }

  onEditUser(user) {
    if (user.deleted_at) {
      this.snackSvc.openSnackBar('Restore user to edit');
      return;
    }
    let ref = null;
    const idx = this.users.findIndex(u => '' + u.id === '' + user.referred_by);
    if (idx >= 0) {
      ref = this.users[idx].name;
    }
    const dialogRef = this.dialog.open(EditUserComponent, {
      maxWidth: '90vw',
      minHeight: '80vh',
      width: '1024px',
      data: {'user': user, 'referred_by': ref}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        user = result.user;
        const uIdx = this.usersFiltered.findIndex(u => u.id === user.id);
        user.tours = this.usersFiltered[uIdx].tours;
        user.tour_pax = this.usersFiltered[uIdx].tour_pax;
        user.voucher = this.usersFiltered[uIdx].voucher;
        this.usersFiltered[uIdx] = user;
      } else {
        // console.log('Didn\'t update user');
      }
    });
  }

  onDeleteUser(user) {
    this.loading = true;
    this.httpUser.deleteUser(user.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.status < 400) {
            user.deleted_at = true;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error deleting user');
        });
  }

  onForceDeleteUser(user) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Force delete ' + user.name + ' !?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.loading = true;
          this.httpUser.deleteUser(user.id, this.currentUser.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                this.loading = false;
                console.log(res);
                if (res.status < 400) {
                  user.deleted_at = true;
                  // Get all users
                  const idx = this.usersFiltered.findIndex(u => u.id === user.id);
                  if (idx >= 0) {
                    this.usersFiltered.splice(idx, 1);
                  }
                  const idx2 = this.users.findIndex(u => u.id === user.id);
                  if (idx2 >= 0) {
                    this.users.splice(idx2, 1);
                  }
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                this.loading = false;
                console.log(error);
                this.snackSvc.openSnackBar('Error force deleting user');
              });
        }
      });
  }

  onRestoreUser(user) {
    const params = {
      'req_id': this.currentUser.id,
      'restore': true,
    };
    this.httpUser.updateUser(user.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          user.deleted_at = null;
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Restoring user');
      });
  }

  iUsers(i) {
    this.skip = ((i - 1) * this.take);
    if ((this.skip >= 0) && (this.skip <= this.total)) {
      this.getAllUsers();
    } else {
      // console.log('No more previous');
    }
  }

  nextUsers() {
    if (this.skip + this.take <= this.total) {
      this.skip += this.take;
      this.getAllUsers();
    }
  }

  prevUsers() {
    if (this.skip - this.take >= 0) {
      this.skip -= this.take;
      this.getAllUsers();
    } else {
      // console.log('No more previous');
    }
  }

  addReferral(user, friend) {
    const data = {
      'user_id': user.id,
      'friend_id': friend.id,
      'status': 0,
    };
    // console.log(data);
    this.httpUser.createReferral(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const ufidx = this.usersFiltered.findIndex(u => u.id === user.id);
            if (ufidx >= 0) {
              this.usersFiltered[ufidx].referrals.push(res.results.referral);
            }
            this.snackSvc.openSnackBar('Referral added');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message);
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  editRef(referral) {
    const new_st = referral.status ? 0 : 1;
    const data = {
      'status': new_st,
    };
    // console.log(data);
    this.httpUser.updateReferral(referral.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            referral.status = new_st;
            this.snackSvc.openSnackBar('Referral ' + (new_st === 1 ? 'active' : 'inactive'));
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  removeReferral(referral) {
    this.httpUser.deleteReferral(referral.id, 2, 'false')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar('Referral deleted');
            const idx = this.usersFiltered.findIndex(u => '' + u.id === '' + referral.user_id);
            if (idx > 0) {
              const idx2 = this.usersFiltered[idx].referrals.findIndex(r => '' + r.id === '' + referral.id);
              this.usersFiltered[idx].referrals.splice(idx2, 1);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  sendCredit(user) {
    const dialogRef = this.dialog.open(AddCreditComponent, {
      autoFocus: true,
      data: {user_id: user.id, user_name: user.given_name}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const data = {};
        data['credit'] = user.user_settings.credit + Number(result['amount']) * 100;
        // console.log(data);
        this.loading = true;
        this.httpUser.updateUserSettings(user.id, data)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              this.loading = false;
              console.log(res);
              if (res.status < 400) {
                user.user_settings.credit = data['credit'];
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              this.loading = false;
              console.log(error);
            });
      }
    });
  }

  onViewAs(user) {
    this.loading = true;
    this.httpTour.getToursUser(user.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.status === 200) {
            localStorage.setItem('cities', JSON.stringify(res.results.cities));
            localStorage.setItem('compTypes', JSON.stringify(res.results.comp_types));
            localStorage.setItem('gssTypes', JSON.stringify(res.results.gss_types));
            localStorage.setItem('companies', JSON.stringify(res.results.companies));
            this.activeTourSvc.setToursUser(res.results.tours);
            this.currentUser.view_as = user.id;
            this.authSvc.updateUser(this.currentUser);
            this.router.navigate(['tours'], {queryParams: {viewAs: user.id}});
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR View as');
          console.log(error);
        });
  }

  createVoucher(user) {
    const dialogRef = this.dialog.open(AddVoucherComponent, {
      autoFocus: true,
      data: {user: user}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        user.voucher.push(result['voucher']);
      }
    });
  }

  onChangeOptions(user, txt) {
    this.loading = true;
    user.user_settings[txt] = !user.user_settings[txt];
    const data = {};
    data[txt] = user.user_settings[txt];
    this.httpUser.updateUserSettings(user.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.status < 400) {
            // this.getAllUsers();
          } else {
            user.user_settings[txt] = !user.user_settings[txt];
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.loading = false;
          user.user_settings[txt] = !user.user_settings[txt];
          console.log(error);
        });
  }

  recentlyOnline(tours: Tour[]) {
    const min = 15;
    return tours && tours.filter(t => new Date(t.updated_at).getTime() + min * 60 * 1000 >= new Date().getTime()).length > 0;
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  onNewVersion() {
    const dialogRef = this.dialog.open(UpdateVersionComponent, {
      autoFocus: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
