import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpItineraryService {
  private endpoints: {
    crudItinerary: string,
    itineraryTour: string,
    allItineraries: string,
    crudDay: string,
    fullDay: string,
    days: string,
    daysTour: string,
    createDaysTour: string,
    rewriteDaysTour: string,
    recreateSCs: string,
    recreateFTs: string,
    recreateOthers: string,
    crudEvent: string,
    events: string,
    eventsTour: string,
    eventsDay: string,
    eventsComponent: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      crudItinerary: environment.apiUrl + 'itinerary/',
      itineraryTour: environment.apiUrl + 'itinerary_tour/',
      allItineraries: environment.apiUrl + 'itineraries/',
      crudDay: environment.apiUrl + 'day/',
      fullDay: environment.apiUrl + 'day_full/',
      days: environment.apiUrl + 'days/',
      daysTour: environment.apiUrl + 'days_tour/',
      createDaysTour: environment.apiUrl + 'create_days_tour/',
      rewriteDaysTour: environment.apiUrl + 'rewrite_days_tour/',
      recreateSCs: environment.apiUrl + 'recreate_safety_checks/',
      recreateFTs: environment.apiUrl + 'recreate_freetimes/',
      recreateOthers: environment.apiUrl + 'recreate_other_comps/',
      crudEvent: environment.apiUrl + 'event/',
      events: environment.apiUrl + 'events/',
      eventsTour: environment.apiUrl + 'events_tour/',
      eventsDay: environment.apiUrl + 'events_day/',
      eventsComponent: environment.apiUrl + 'events_component/',
    };
  }

  // ITINERARY

  /* Gets all itinerary_tour from one tour (creating tour also returns them) */
  getItineraryTour(tour_id: string): Observable<any> {
    return this.http.get<any>(this.endpoints.itineraryTour + tour_id);
  }

  /* Update itinerary_tour */
  updateItineraryTour(itin_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudItinerary + itin_id, data);
    // return this.http.post<any>(this.endpoints.crudItinerary, data);
  }

  async updateItinerary2(itin_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudItinerary + itin_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Add itinerary_tour*/
  addItineraryTour(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudItinerary, data);
  }

  /* Delete itinerary_tour*/
  deleteItineraryTour(itin_id: string, force?: string): Observable<any> {
    let params = new HttpParams().set('itin_id', itin_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudItinerary, {params: params});
  }

  /* Delete itinerary_tour*/
  deleteItinerariesTour(tour_id: number): Observable<any> {
    return this.http.delete<any>(this.endpoints.itineraryTour + tour_id);
  }

  // DAY

  /* Gets all Days */
  getDays(): Observable<any> {
    return this.http.get<any>(this.endpoints.days);
  }

  /* Gets a particular Day */
  getDay(req_id: number, day_id: number): Observable<any> {
    const params = new HttpParams()
      .set('day_id', day_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.crudDay, {params: params});
  }

  /* Gets a particular full info Day */
  getDayFull(req_id: number, day_id: number): Observable<any> {
    const params = new HttpParams()
      .set('day_id', day_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.fullDay, {params: params});
  }

  /* Get all days for tour */
  getDaysTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.daysTour + tour_id);
  }

  /* Creates all days for tour */
  createDaysTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.createDaysTour + tour_id);
  }

  reWriteDays(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.rewriteDaysTour + tour_id);
  }

  recreateSafetyChecks(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.recreateSCs + tour_id);
  }

  recreateSFTs(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.recreateFTs + tour_id);
  }

  recreateOthers(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.recreateOthers + tour_id);
  }

  /* Stores a particular Day */
  createDay(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudDay, data);
  }

  /* Updates a particular Day */
  updateDay(day_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.crudDay + day_id, data);
  }

  /* Deletes a particular Day */
  deleteDay(day_id: string, force?: string): Observable<any> {
    let params = new HttpParams().set('day_id', day_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudDay, {params: params});
  }

  // EVENT

  /* Gets all events */
  getEvents(): Observable<any> {
    return this.http.get<any>(this.endpoints.events);
  }

  /* Gets all events */
  getdayFull(day_id: string): Observable<any> {
    return this.http.get<any>(this.endpoints.eventsDay + day_id);
  }

  /* Gets a particular event */
  getEvent(req_id: number, event_id: string): Observable<any> {
    const params = new HttpParams()
      .set('event_id', event_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.crudEvent, {params: params});
  }

  /* Stores an event */
  createEvent(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudEvent, data);
  }

  /* Updates an event */
  updateEvent(event_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudEvent + event_id, data);
  }

  /* Deletes an event */
  deleteEvent(req_id: number, event_id: string, force?: string): Observable<any> {
    let params = new HttpParams()
      .set('event_id', event_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudEvent, {params: params});
  }

}
