import {Component, OnDestroy, OnInit} from '@angular/core';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {HttpFlightService} from '../../../../../shared/services/http/http-flight.service';
import {FlightTrack} from '../../../../../shared/models/tour-pax-flight.model';

@Component({
  selector: 'app-tour-flights',
  templateUrl: './tour-flights.component.html',
  styleUrls: ['./tour-flights.component.scss']
})
export class TourFlightsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  arrivals: FlightTrack[] = [];
  departures: FlightTrack[] = [];

  loadingFlights = false;
  returnUrl: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private activeTourSvc: ActivetourService,
    private titleSvc: TitleService,
    private httpFlight: HttpFlightService,
    private snackSvc: SnackbarService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    this.titleSvc.setTitle('Tour Flights');
    const toursUser = this.activeTourSvc.getToursUser();
    const tour_prodid = this.route.snapshot.params['tour-prodid'];
    if (this.activeTour.itinerary.length <= 0) {
      const tour = toursUser.find(x => '' + x.prodid === '' + tour_prodid);
      if (!toursUser || !tour) {
        this.snackSvc.openSnackBar('Error. Invalid tour');
        this.router.navigate(['tours']);
        return;
      }
    }
    this.getTourFlights(this.activeTour.tour.id);
  }

  getTourFlights(tour_id: number) {
    this.loadingFlights = true;
    this.httpFlight.getFlightsTour(tour_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.arrivals = res.results.arrivals;
            this.departures = res.results.departures;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingFlights = false;
        },
        error => {
          console.log(error);
          this.loadingFlights = false;
        });
  }

  onAddFlight() {
    this.snackSvc.openSnackBar('Coming soon!');
  }

  onRefreshF() {
    this.getTourFlights(this.activeTour.tour.id);
  }

  goBack() {
    if (this.returnUrl && this.returnUrl === window.location.pathname) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels'], {queryParams: {returnUrl: window.location.pathname}});
    } else if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
