import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {TitleService} from '../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {Orders} from '../../../../shared/models/orders.model';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {Location} from '@angular/common';
import {loadStripe} from '@stripe/stripe-js';
import {Billing} from '../../../../shared/models/billing.model';
import {HttpInvoiceService} from '../../../../shared/services/http/http-invoice.service';
import {Invoice} from '../../../../shared/models/invoice.model';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {CreateInvoiceComponent} from '../../../components/admin/create-invoice/create-invoice.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  currentUser: User;

  order_list: boolean;
  orders: Orders[] = [];
  paid: Orders[] = [];
  order: Orders;
  billing: Billing;
  invoice: Invoice;
  payment_intent: any;
  req_id: number;
  viewAs: number;
  viewAsName: string;

  indexIFerror: string[] = [];

  tour_deleted: boolean;
  loading = false;
  pdf_loading = false;
  isAdmin = false;
  baseUrlLogo: string;

  stripePromise: any = null;
  stripe: any = null;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private titleSvc: TitleService,
private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private flagService: FlagService,
    private httpInvoiceService: HttpInvoiceService,
    private location: Location,
    private httpOrder: HttpOrdersService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.viewAs = this.route.snapshot.queryParams.viewAs || this.currentUser && this.currentUser.view_as || null;
      });
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
    this.order = new Orders();
  }

  ngOnInit() {
    const order_id = this.route.snapshot.params['order_id'] ? this.route.snapshot.params['order_id'] : null;
    const ref = this.route.snapshot.queryParams['ref'] ? this.route.snapshot.queryParams['ref'] : null;
    const sessionId = this.route.snapshot.queryParams['session_id'] ? this.route.snapshot.queryParams['session_id'] : null;
    this.baseUrlLogo = environment.baseUrl;
    this.order_list = !(!!order_id);
    this.isAdmin = this.currentUser.role === User.admin;
    if (this.viewAs && this.currentUser.role >= User.admin) {
      this.currentUser.view_as = this.viewAs;
    } else {
      if (this.route.snapshot.queryParams.viewAs) {
        this.snackSvc.openSnackBar('Not allowed');
      }
    }
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'viewAs': null,
      },
      queryParamsHandling: 'merge'
    });
    this.authSvc.updateUser(this.currentUser);
    this.req_id = this.viewAs && this.currentUser.view_as ? this.viewAs : this.currentUser.id;
    if (order_id) {
      this.getOrderFull(order_id);
    } else {
      this.titleSvc.setTitle('Orders');
      this.getOrdersUser(sessionId, ref);
    }
  }

  printOrds() {
    console.log(this.orders);
    console.log(this.order);
    console.log(this.payment_intent);
  }

  /*
/* HTTP REQUESTS
*******************/
  getOrdersUser(sessionId, ref) {
    this.loading = true;
    this.httpOrder.getOrdersUser(this.req_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.orders = res.results.orders;
            if (this.currentUser.view_as) {
              this.viewAsName = res.results.user.given_name + ': ' + res.results.user.id;
            }
            const idx = this.orders.findIndex(it => '' + it.reference === '' + ref);
            if (idx >= 0 && this.orders[idx].status !== 'Complete') {
              if (sessionId && sessionId === 'error') {
                this.location.replaceState('/orders');
                this.snackSvc.openSnackBar('Error in payment: ' + ref);
                this.indexIFerror.push(ref);
              }
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  getOrderFull(order_id) {
    this.loading = true;
    this.httpOrder.getOrderFull(order_id, this.req_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.order = res.results.order || null;
            this.invoice = res.results.invoice || null;
            this.billing = res.results.billing || null;
            this.paid = res.results.paid || null;
            this.tour_deleted = res.results.tour === null;
            if (!this.tour_deleted) {
              this.order.t_logo = res.results.tour.logo;
              this.order.t_name = res.results.tour.name;
            }
            this.titleSvc.setTitle('Order#' + this.order.reference);
            this.payment_intent = res.results.payment_intent;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
            this.router.navigate(['orders']);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  /*
/* STRIPE
*******************/

  resumeSession(order: Orders) {
    const condTotry = this.paid.length <= 0;
    if (condTotry) {
      this.stripe.redirectToCheckout({sessionId: order.stripe.session_id})
        .then(ref => {
          console.log(ref);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      const snackbarRef = this.snackSvc.openSnackBar('Tour already paid', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            const prodid = order.t_idcode.split('#')[0];
            this.router.navigate(['tour', prodid]);
          }
        });
    }
  }

  goPay(order: Orders) {
    const prodid = order.t_idcode.split('#')[0];
    this.router.navigate(['summary', prodid], {queryParams: {returnUrl: window.location.pathname}});
  }

  onRequestInvoice(order) {
    if (!this.billing) {
      this.billing = new Billing(this.currentUser.view_as ? this.viewAsName : this.currentUser.name);
    }
    const dialogRef = this.dialog.open(CreateInvoiceComponent, {
      minWidth: '66vw',
      autoFocus: true,
      data: {'user_id': this.req_id, 'order_id': order.id, 'billing': this.billing, invoice: this.invoice, reason: null}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        this.invoice = result['invoice'];
        this.snackSvc.openSnackBar('Invoice processing. Will be sent by mail');
      } else {
        // console.log('Cancel request invoice');
      }
    });
  }

  goOrder(order) {
    this.indexIFerror.splice(this.indexIFerror.findIndex(it => '' + it === '' + order.reference), 1);
    this.router.navigate(['orders', order.id]);
  }

  deleteOrder(order) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete order?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.loading = true;
          this.httpOrder.deleteOrder(order.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status === 204) {
                  // Remove from memory
                  const idx = this.orders.findIndex(it => '' + it.id === '' + order.id);
                  this.orders.splice(idx, 1);
                  this.loading = false;
                  this.router.navigate(['orders']);
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    if (!res.message.error_description) {
                      this.snackSvc.openSnackBar(res.message.message.toString());
                    } else {
                      this.snackSvc.openSnackBar(res.message.error_description.toString());
                    }
                  }
                }
                this.loading = false;
              },
              error => {
                this.loading = false;
                console.log(error);
              });
        }
      });
  }

  downloadReceiptPDF(order) {
    this.pdf_loading = true;
    this.httpOrder.downloadOrderPDF(order.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        x => {
          const newBlob = new Blob([x], {type: 'application/pdf'});
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data;
          link.download = 'planafy_' + order.t_idcode + '.pdf';
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
          this.pdf_loading = false;
        },
        error => {
          this.pdf_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error downloading order');
        });
  }

  onRemoveView() {
    this.currentUser.view_as = null;
    this.authSvc.updateUser(this.currentUser);
    this.viewAs = null;
    this.viewAsName = null;
    this.req_id = this.currentUser.id;
    this.getOrdersUser(null, null);
  }

  goBack() {
    this.router.navigate(['orders']);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
