import {Injectable, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {AuthRoutingModule} from './auth/auth-routing.module';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {LegalRoutingModule} from './legal/legal-routing.module';
import {ServiceModule} from './shared/services/service.module';
import {LandingComponent} from './landing/landing.component';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import 'hammerjs';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {ConfirmationDialogComponent} from './shared/components/confirmation-dialog/confirmation-dialog.component';
import {HomeRoutingModule} from './main/home-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './shared/material.module';
import {LayoutComponent} from './main/components/layout/layout.component';
import localeEs from '@angular/common/locales/es';
import {MySnackbarComponent} from './shared/components/my-snackbar/my-snackbar.component';

registerLocaleData(localeEs, 'es-ES');

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'pan-y'
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    PageNotFoundComponent,
    LandingComponent,
    ConfirmationDialogComponent,
    MySnackbarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HammerModule,
    HttpClientModule,
    AuthRoutingModule,
    HomeRoutingModule,
    LegalRoutingModule,
    MaterialModule,
    ServiceModule,
    RouterModule.forRoot([
        {path: '', component: LandingComponent},
        {path: '**', component: PageNotFoundComponent}
      ],
      {
        enableTracing: false,
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled'
      }
    )
  ],
  providers: [
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
