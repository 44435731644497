import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ComponentTime {
  name: string;
  start_time: string;
  end_time: string;
  sc: boolean;
  hotel: boolean;
}

@Component({
  selector: 'app-time-changer',
  templateUrl: './time-changer.component.html',
  styleUrls: ['./time-changer.component.scss']
})
export class TimeChangerComponent {

  hours: string;
  minutes: string;
  show_end: boolean;

  constructor(
    public dialogRef: MatDialogRef<TimeChangerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentTime) {
    if (this.data?.start_time?.includes(':')) {
      const tmp = this.data.start_time.split(':');
      this.hours = tmp[0];
      this.minutes = tmp[1];
    }
    this.show_end = !!data.end_time;
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.close('close');
    } else if (event.key === 'Enter') {
      this.submit();
    }
  }

  changeTime(event, data, type) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.substring(0, 5);
    inputValue = inputValue.replace(/[^\d:]/g, '');
    if (inputValue === ':') {
      inputValue = null;
    }
    if (inputValue) {
      let [hours, minutes] = inputValue.split(':').map(Number);
      hours = isNaN(hours) ? 0 : hours;
      minutes = isNaN(minutes) ? 0 : minutes;
      const time = new Date();
      time.setHours(hours, minutes);
      inputValue = time.toLocaleTimeString(['es-ES'], {hour: '2-digit', minute: '2-digit'});
    }
    data[type] = inputValue;
  }

  inputTime(event, data, type) {
    let inputValue: string = event.target.value;
    if (event.data === ' ' && inputValue.length === 2) {
      inputValue = '0' + event.target.value[0] + ':';
    }
    if (inputValue.length === 2 && inputValue.indexOf(':') === -1) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }
    data[type] = inputValue.substring(0, 5);
  }

  onChangedTime() {
    this.data.start_time = this.hours + ':' + this.minutes;
  }

  onFocusEnd() {
    if (this.data.start_time && !this.data.end_time && (!this.data.hotel || this.data.hotel && this.data.name?.includes('Breakfast'))) {
      if (this.data.start_time) {
        const [startHour, startMinute] = this.data.start_time.split(':').map(Number);
        const endTime = new Date();
        endTime.setHours(startHour + 1, startMinute);
        this.data.end_time = endTime.toLocaleTimeString(['es-ES'], {hour: '2-digit', minute: '2-digit'});
      }
    }
  }

  submit(): void {
    this.close(this.data);
  }

  close(reason) {
    this.dialogRef.close(reason);
  }
}
