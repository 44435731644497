import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {TitleService} from '../../../../shared/services/common/title.service';
import {HttpIncidentService} from '../../../../shared/services/http/http-incident.service';
import {Incident} from '../../../../shared/models/incident.model';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {IncidentType} from '../../../../shared/models/incident-type.model';
import {AddCityComponent} from '../../../components/add-city/add-city.component';
import {MatDialog} from '@angular/material/dialog';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {VersioningService} from '../../../../shared/services/common/versioning.service';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss']
})
export class IncidentComponent implements OnInit, OnDestroy {
  currentUser: User;
  loading = false;

  incident: Incident;
  incidents: Incident[] = [];
  incident_types: IncidentType[] = [];
  cities: any[] = [];

  city_id: number;
  incident_type_id: number;
  enableEndDate = false;
  startdate = '';
  enddate = '';
  starttime = '';
  endtime = '';
  details = '';
  inc_id = null;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private titleSvc: TitleService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private versioning: VersioningService,
    private textTransformService: TextTransformService,
    private httpIncidentService: HttpIncidentService,
    private httpCitySvc: HttpCityService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.titleSvc.setTitle('Incident report');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
    this.incident = new Incident();
    this.incident.user_id = this.currentUser.id;
    this.getAllIncidentTypes();
    this.getAllCities();
    this.getUserIncidents();
  }

  printinfo() {
    console.log(this.incident);
  }

  getAllCities() {
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.versioning.check(res.results.version);
            // Insert all cities
            this.cities = [{id: 0, name: '** Not Found **', country: 'Add new'}].concat(res.results.cities);
            this.city_id = null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  getAllIncidentTypes() {
    this.httpIncidentService.getAllIncidentTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Insert all incident types
            this.incident_types = res.results.incident_types;
            this.incident_type_id = null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error fetching incident types');
        });
  }

  getUserIncidents() {
    this.loading = true;
    this.httpIncidentService.getAllIncidentsUser(this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            // Insert all cities
            this.incidents = res.results.incidents;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all incidents');
        });
  }

  onSelectCity(event) {
    if ('' + event.target.value === '' + 0) {
      this.onAddCity();
    }
  }

  onIncidentChange(event) {
    if (event.target.value === '') {
      return;
    } else {
      this.incident_type_id = event.target.value;
    }
  }

  saveIncident() {
    if (!this.city_id || this.city_id < 1) {
      this.snackSvc.openSnackBar('City is required');
      return;
    }
    if (!this.incident_type_id || this.incident_type_id < 1) {
      this.snackSvc.openSnackBar('Incident is required');
      return;
    }
    if (!this.startdate) {
      this.snackSvc.openSnackBar('Start date is required');
      return;
    } else {
      if (!this.textTransformService.isValidDate(this.startdate)) {
        this.snackSvc.openSnackBar('Date format yyyy-mm-dd');
        return;
      }
      let st = '00:00';
      if (this.starttime) {
        if (!/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this.starttime)) {
          this.snackSvc.openSnackBar('Time in 24h format');
          return;
        } else {
          st = this.starttime;
        }
      }
      this.incident.start_datetime = this.startdate + ' ' + st + ':00';
    }
    if (this.endtime && !this.enddate) {
      this.snackSvc.openSnackBar('Set an end date');
      return;
    }
    if ((this.enddate < this.startdate) && this.enableEndDate) {
      this.snackSvc.openSnackBar('Set correct dates');
      return;
    }
    if (!this.enddate) {
      this.incident.end_datetime = null;
    } else {
      if (!this.textTransformService.isValidDate(this.enddate)) {
        this.snackSvc.openSnackBar('Date format yyyy-mm-dd');
        return;
      }
      let end_time = '00:00';
      if (this.endtime) {
        if (!/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this.endtime)) {
          this.snackSvc.openSnackBar('Time in 24h format');
          return;
        } else {
          end_time = this.endtime;
        }
      }
      this.incident.end_datetime = this.enddate + ' ' + end_time + ':00';
    }
    if (!this.details) {
      this.snackSvc.openSnackBar('Some details are required');
      return;
    }
    if (!this.enableEndDate) {
      this.incident.end_datetime = null;
    }
    const data = {
      'user_id': this.currentUser.id,
      'incident_type_id': this.incident_type_id,
      'city_id': this.city_id,
      'details': this.details,
      'start_datetime': this.incident.start_datetime,
      'end_datetime': this.incident.end_datetime,
      'n_actives': this.incident.n_actives,
      'n_inactives': this.incident.n_inactives,
    };
    if (this.inc_id) {
      this.httpIncidentService.updateIncident(this.inc_id, data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              const itemIndex = this.incidents.findIndex(item => '' + item.id === '' + this.inc_id);
              this.incidents[itemIndex] = res.results.incident;
              this.snackSvc.openSnackBar('Incident updated');
              this.onClear();
            } else {
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
          });
    } else {
      this.httpIncidentService.createIncident(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.incidents.push(res.results.incident);
              this.onClear();
              this.snackSvc.openSnackBar('Thank you for your report');
            } else {
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
          });
    }
  }

  onClear() {
    this.inc_id = null;
    this.city_id = -1;
    this.incident_type_id = -1;
    this.startdate = null;
    this.starttime = null;
    this.enddate = null;
    this.endtime = null;
    this.details = null;
    this.enableEndDate = false;
  }

  onEdit(id) {
    this.onClear();
    const incident = this.incidents.find(it => +it.id === +id);
    this.inc_id = incident.id;
    this.city_id = incident.city_id;
    this.incident_type_id = incident.incident_type_id;
    this.details = incident.details;
    const start = incident.start_datetime;
    const end = incident.end_datetime;
    const local_start = new Date(start);
    const local_end = new Date(end);
    this.startdate = this.datepipe.transform(local_start, 'yyyy-MM-dd');
    this.starttime = this.datepipe.transform(local_start, 'HH:mm');
    if (incident.end_datetime) {
      this.enableEndDate = true;
      this.enddate = this.datepipe.transform(local_end, 'yyyy-MM-dd');
      this.endtime = this.datepipe.transform(local_end, 'HH:mm');
    }
  }

  onDeleteIncident(id) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete incident?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpIncidentService.deleteIncident(id, this.currentUser.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  this.incidents.splice(this.incidents.findIndex(it => '' + it.id === '' + id), 1);
                  this.onClear();
                } else {
                  this.snackSvc.resultsElse(res);
                }
              },
              error => {
                console.log(error);
              });
        }
      });
  }

  onAddCity() {
    const dialogRef = this.dialog.open(AddCityComponent, {
      autoFocus: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'confirmed': true,
          'name': this.textTransformService.titleCase(result['name']),
          'country': this.textTransformService.titleCase(result['country']),
        };
        this.httpCitySvc.saveCity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const city = res.results.city;
                const CityIdx = this.cities.findIndex(x => x.name === city.name && x.country === city.country);
                if (CityIdx < 0) {
                  this.cities.push(city);
                  localStorage.setItem('cities', JSON.stringify(this.cities));
                } else {
                  this.snackSvc.openSnackBar('City already exists');
                }
                this.city_id = city.id;
                this.cities.sort(function (a, b) {
                  return a.name[0].localeCompare(b.name[0]);
                });
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      } else {
        this.city_id = null;
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
