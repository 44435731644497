import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {User} from '../../../../../shared/models/user.model';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {Location} from '@angular/common';
import {environment} from '../../../../../../environments/environment';
import {Company} from '../../../../../shared/models/company.model';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {OutlookService} from '../../../../../shared/services/session/outlook.service';
import {FlagService} from '../../../../../shared/services/common/flag.service';
import {TourService} from '../../../../../shared/services/common/tour.service';

@Component({
  selector: 'app-tour-info',
  templateUrl: './tour-info.component.html',
  styleUrls: ['./tour-info.component.scss']
})
export class TourInfoComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;

  companies: Company[];
  code_holder = 'XXXB';
  baseUrlLogo: string;
  loading = false;
  loading_go = false;

  prod_id: string;
  edit = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpTour: HttpTourService,
    private activeTourSvc: ActivetourService,
    private titleSvc: TitleService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
    private router: Router,
    private tourSvc: TourService,
    private flagService: FlagService,
    private outlookSvc: OutlookService,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.titleSvc.setTitle('Tour Info');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.companies = JSON.parse(localStorage.getItem('companies'));
  }

  ngOnInit() {
    if (!this.activeTour) {
      this.activeTour = new Activetour();
    }
    if (this.currentUser.role < User.admin) {
      this.companies = this.companies.filter(it => it.id !== 1);
    }
    this.baseUrlLogo = environment.baseUrl;
    this.prod_id = this.route.snapshot.params['tour-prodid'];
    this.edit = (this.prod_id !== null) && (this.activeTour.tour.prodid !== null);
    if (this.activeTour.tour.prodid) {
      if (this.edit && this.activeTour.tour.prodid + '' !== this.prod_id) {
        this.snackSvc.openSnackBar('Error. Wrong tour?');
        this.router.navigate(['tours']);
        return;
      }
      if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
        this.snackSvc.openSnackBar('Error. Not your tour?');
        this.router.navigate(['tours']);
        return;
      }
      this.getTour(this.activeTour.tour.id, this.currentUser.id);
    }
    if (!this.edit || !this.activeTour.tour.prodid) {
      this.titleSvc.setTitle('New Tour');
      this.location.replaceState('/tourinfo');
    }
  }

  info() {
    console.log(this.activeTour);
  }

  checkProd() {
    if (!/^\d{6}$/.test(this.activeTour.tour.prodid)) {
      // this.snackSvc.openSnackBar('⚠ Prodid is a 6 digit number');
    }
  }

  codeUpperCase() {
    this.activeTour.tour.code = this.activeTour.tour.code.toUpperCase();
  }

  uploadLogoFile(event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.loading = true;
      this.ngxPicaService.compressImage(file, 512)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 200, 150, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('tour_id', '' + this.activeTour.tour.id);
              this.httpTour.sendLogo(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    this.loading = false;
                    if (res.status < 400) {
                      this.activeTour.tour.logo = res.results.tour.logo;
                      this.activeTourSvc.setActiveTour(this.activeTour);
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    this.loading = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('Error. Contact support');
                    this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error resizeImage(): ' +
                      JSON.stringify(error), formData.toString());
                  });
            }, (err: NgxPicaErrorInterface) => {
              this.loading = false;
              console.log('company logo not resized');
              console.log(err.err);
            });
        }, (err: NgxPicaErrorInterface) => {
          this.loading = false;
          console.log('company logo not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  onOKtour() {
    if (!this.activeTour.tour.prodid) {
      this.snackSvc.openSnackBar('Error. Prodid required');
      return;
    }
    if (!this.activeTour.tour.code) {
      this.snackSvc.openSnackBar('Error. Code required');
      return;
    }
    if (!this.activeTour.tour.name) {
      this.snackSvc.openSnackBar('Error. Name required');
      return;
    }
    if (!this.activeTour.tour.company_id) {
      this.snackSvc.openSnackBar('Error. Company required');
      return;
    }
    this.loading_go = true;
    const params = [
      {'key': 'code', 'value': this.activeTour.tour.code},
      {'key': 'logo', 'value': this.activeTour.tour.logo},
      {'key': 'name', 'value': this.activeTour.tour.name},
      {'key': 'company_id', 'value': this.activeTour.tour.company_id}
    ];
    if (!this.edit) {
      params.push({'key': 'user_id', 'value': this.currentUser.id});
      params.push({'key': 'prodid', 'value': this.activeTour.tour.prodid});
      this.createTour(params);
    } else {
      params.push({'key': 'id', 'value': this.activeTour.tour.id});
      this.updateTour(params, true);
    }
  }

  getTour(tour_id, user_id) {
    // Get tour
    this.httpTour.getTour(tour_id, user_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.activeTour.tour = res.results.tour;
            this.activeTourSvc.setActiveTour(this.activeTour);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error getTour()', JSON.stringify(error));
        });
  }

  deleteLogo() {
    this.activeTour.tour.logo = '';
    this.updateTour([{'key': 'logo', 'value': ''}, {'key': 'del_logo', 'value': true}], false);
  }

  createTour(values) {
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      params[i.key] = '' + i.value;
    }
    // console.log(params);
    this.httpTour.createTour(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.activeTour.tour = res.results.tour;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.router.navigate(['paxinfo', this.activeTour.tour.prodid]);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  updateTour(values: any, go: boolean) {
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      params[i.key] = '' + i.value;
    }
    // console.log(params);
    this.httpTour.updateTour(this.activeTour.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 203) {
          for (const i of values) {
            this.activeTour.tour[i.key] = res.results.tour[i.key];
          }
          this.activeTour.tour.logo = res.results.tour.logo;
          this.activeTour.tour.company_id = res.results.tour.company_id;
          this.activeTourSvc.setActiveTour(this.activeTour);
          if (go) {
            this.router.navigate(['paxinfo', this.activeTour.tour.prodid]);
          }
        } else {
          this.snackSvc.resultsElse(res);
          this.router.navigate(['tours']);
        }
        setTimeout(() => {
          this.loading_go = false;
        }, 1000);
      }, error => {
        console.log(error);
        this.loading_go = false;
        this.snackSvc.openSnackBar('Error. Contact support');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error updateTour(): ' + JSON.stringify(error), params.toString());
      });
  }

  onSelectionCiaChange(event) {
    this.activeTour.tour.company_id = event.value;
    this.activeTour.tour.company = this.companies.find(c => '' + c.id === '' + event.value);
    this.activeTour.tour.logo = this.activeTour.tour.company.logo;
    this.activeTourSvc.setActiveTour(this.activeTour);
    if (this.activeTour.tour.company_id === 1) {
      this.code_holder = 'XXXX';
    } else if (this.activeTour.tour.company_id === 2) {
      this.code_holder = 'XXXB';
    } else if (this.activeTour.tour.company_id === 3) {
      this.code_holder = 'XXX';
    } else if (this.activeTour.tour.company_id === 6) {
      this.code_holder = 'XXX';
    } else {
      this.code_holder = 'XXXX';
    }
  }

  onGoBack() {
    this.router.navigate(['tours']);
  }

  // Delete given tour
  onDeleteTour(tour) {
    this.httpTour.deleteTour(tour.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 204) {
            this.tourSvc.onDeleteTourFolder();
            this.activeTour = new Activetour();
            this.router.navigate(['tours']);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error deleteTourFolder(): ' + JSON.stringify(error), tour.toString());
        });

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
