import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {environment} from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class TitleService {
  private pageTitle = new BehaviorSubject<string>('Planafy');
  currentTitle = this.pageTitle.asObservable();

  constructor(private webTitleSvc: Title) {
  }

  setTitle(name: string): void {
    const newTitle = environment.titlePrefix + name;
    this.pageTitle.next(newTitle);
    if (name !== 'Planafy') {
      this.webTitleSvc.setTitle(newTitle + ' | Planafy');
    } else {
      this.webTitleSvc.setTitle(environment.titlePrefix + 'Home | Planafy');
    }
  }
}
