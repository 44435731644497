import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {Tour} from '../../../../shared/models/tour.model';
import {Hotel} from '../../../../shared/models/hotel.model';
import {User} from '../../../../shared/models/user.model';
import {Company} from '../../../../shared/models/company.model';
import {City} from '../../../../shared/models/city.model';
import {HttpItineraryService} from '../../../../shared/services/http/http-itinerary.service';
import {takeUntil} from 'rxjs/operators';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {AddVoucherComponent} from '../add-voucher/add-voucher.component';
import {HttpRoomService} from '../../../../shared/services/http/http-room.service';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import {DupeItinComponent} from '../dupe-itin/dupe-itin.component';
import {DatePipe} from '@angular/common';
import {Itinerary} from '../../../../shared/models/itinerary.model';
import {Day} from '../../../../shared/models/day.model';
import {Components} from '../../../../shared/models/components.model';
import {Comptype} from '../../../../shared/models/comptype.model';
import {HttpComponentService} from '../../../../shared/services/http/http-component.service';
import {HttpTourOptionalService} from '../../../../shared/services/http/http-tour-optional.service';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {TourOptional} from '../../../../shared/models/tour-optional.model';
import {FlagService} from '../../../../shared/services/common/flag.service';

export interface DialogData {
  reason: string;
  tour: Tour;
  tours: Tour[];
  tds: User[];
  hotels: Hotel[];
}

@Component({
  selector: 'app-edit-tour',
  templateUrl: './edit-tour.component.html',
  styleUrls: ['./edit-tour.component.scss']
})
export class EditTourComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  loading = false;
  untour_components: Components[] = [];
  uncity_components: Components[] = [];
  compTypes: Comptype[] = [];
  companies: Company[] = [];
  cities: City[] = [];
  cities_tour: City[] = [];
  hotels: Hotel[] = [];
  hotelsCity: Hotel[] = [];
  linkURL: string;
  appUrl: string;
  error: string;

  idxEditItin: number[] = [];
  idxEditDay: number[] = [];
  idxEditOrd: number[] = [];

  reason: string;
  user_id: string;
  user_id2: string;
  tour_pax_id: string;
  tour_opt_id: string;
  change_td = false;
  setts_loading = false;
  showUntour = false;
  showUncity = false;
  show_join_ago = true;
  show_join_ago_txt: string;
  show_last_ago = true;
  show_last_ago_txt: string;
  interval: any;

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Enter') {
      this.submit();
    }
  }

  constructor(public dialogRef: MatDialogRef<EditTourComponent>,
              public dialog: MatDialog,
              private router: Router,
              private datePipe: DatePipe,
              private httpTOSvc: HttpTourOptionalService,
              private httpOrder: HttpOrdersService,
              private httpItinerarySvc: HttpItineraryService,
              private httpRoomSvc: HttpRoomService,
              private httpComp: HttpComponentService,
              private httpTour: HttpTourService,
              private httpUser: HttpUserService,
              private snackSvc: SnackbarService,
              private flagService: FlagService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.companies = JSON.parse(localStorage.getItem('companies'));
    this.cities = JSON.parse(localStorage.getItem('cities'));
    this.hotels = this.data.hotels;
  }

  ngOnInit(): void {
    this.appUrl = environment.appUrl;
    this.linkURL = this.appUrl + 'join/' + btoa('' + this.data.tour.id).replace(/=/g, '');
    this.compTypes = JSON.parse(localStorage.getItem('compTypes'));
    this.calcTime();
    this.interval = setInterval(() => {
      this.calcTime();
    }, 1000);
    this.getTour(this.data.tour);
  }

  submit(): void {
    this.onUpdateTour();
  }

  notify() {
    this.snackSvc.openSnackBar('Copied to clipboard');
  }

  goAdminComp(c_id) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['admin', 'comps'], {queryParams: {c: c_id}}));
    window.open(url, '_blank');
  }

  showJoinTime() {
    this.show_join_ago = false;
    setTimeout(() => {
      this.show_join_ago = true;
    }, 5000);
  }

  showLastTime() {
    this.show_last_ago = false;
    setTimeout(() => {
      this.show_last_ago = true;
    }, 5000);
  }

  recentOn(upd) {
    const min = 4; // 3min green
    return new Date(upd).getTime() + min * 60 * 1000 >= new Date().getTime();
  }

  calcTime() {
    let time_amount;
    let time_units;
    const startDate = new Date(this.data.tour.updated_at);
    const endDate = new Date();
    let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_last_ago_txt = time_amount + ' ' + time_units + ' ago';
    const startDate2 = new Date(this.data.tour.created_at);
    const endDate2 = new Date();
    seconds = (endDate2.getTime() - startDate2.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_join_ago_txt = time_amount + ' ' + time_units + ' ago';
  }

  info() {
    console.log(this.data.tour);
  }

  // TOUR

  chgCia() {
    const idxCia = this.companies.findIndex(c => +c.id === +this.data.tour.company_id);
    this.data.tour.logo = this.companies[idxCia].logo;
  }

  chgTD() {
    this.change_td = true;
  }

  async getTour(tour: Tour) {
    // Get tour async
    this.loading = true;
    const res = await this.httpTour.getTourAsync(tour.id, 2);
    console.log(res);
    if (res.status < 400) {
      let rooms = [], days = [];
      if (this.data.tour.itinerary) {
        rooms = this.data.tour.itinerary.filter(it => it.show_rooms);
        days = this.data.tour.itinerary.filter(it => it.show_days);
      }
      this.data.tour = {
        ...this.data.tour,
        itinerary: res.results.tour.itinerary,
        tour_pax: res.results.tour.tour_pax,
        orders: res.results.tour.orders,
        tour_optionals: res.results.tour.tour_optionals,
        tour_access: res.results.tour.tour_access,
      };
      this.data.tour.tour_pax.map(tp => {
        if (!tp.user.gender) {
          tp.user.gender = '?';
        }
        if (!tp.room) {
          tp.room = '?';
        }
        tp.last_txt = this.getLastTxt(tp.updated_at);
      });
      this.data.tour.tour_optionals.map(to => {
        to.pay_online = +to.pay_online === 1;
      });
      this.untour_components = res.results.comps_untour;
      this.uncity_components = res.results.comps_uncity;
      this.untour_components.map(c => c.comp_type = this.compTypes.find(ct => +ct.id === +c.comp_type_id));
      this.uncity_components.map(c => c.comp_type = this.compTypes.find(ct => +ct.id === +c.comp_type_id));
      this.linkURL = this.appUrl + 'join/' + btoa('' + this.data.tour.id).replace(/=/g, '');
      this.data.tour.itinerary.map(elem => {
        if (rooms.find(item => item.id === elem.id)) {
          elem.show_rooms = true;
        }
        if (days.find(item => item.id === elem.id)) {
          elem.show_days = true;
        }
      });
      this.cities_tour = this.cities.filter(c => {
        if (this.data.tour.itinerary.map((i) => i.city_id).includes(c.id)) {
          return c;
        }
      });
      this.loading = false;
    } else {
      this.snackSvc.resultsElse(res);
      this.loading = false;
    }
  }

  onUpdateTour() {
    this.loading = true;
    const params = {
      'user_id': this.data.tour.user_id,
      'req_id': '2',
      'company_id': this.data.tour.company_id,
      'name': this.data.tour.name,
      'logo': this.data.tour.logo,
      'cover': this.data.tour.cover,
      'tour_starts': this.data.tour.tour_starts,
      'ndays': this.data.tour.ndays,
    };
    if (this.data.tour.code) {
      params['code'] = this.data.tour.code;
    }
    if (this.data.tour.prodid) {
      params['prodid'] = this.data.tour.prodid;
    }
    if (this.change_td) {
      params['change_td'] = true;
    }
    // console.log(params);
    this.httpTour.updateTour(this.data.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.loading = false;
        console.log(res);
        if (res.status < 400) {
          this.data.tour.user_id = res.results.tour.user.id;
          this.onUpdateTourSettings(true);
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.loading = false;
        this.snackSvc.openSnackBar('ERROR. Updating tour');
        console.log(error);
      });
  }

  onUpdateTourSettings(close?) {
    const params = {
      'visible': this.data.tour.tour_settings.visible,
      'enable_tips': this.data.tour.tour_settings.enable_tips,
      'archived': this.data.tour.tour_settings.archived,
      'plan': this.data.tour.tour_settings.plan,
      'paid_at': this.data.tour.tour_settings.paid_at,
    };
    // console.log(params);
    if (close) {
      this.loading = true;
    }
    this.httpTour.updateTourSettings(this.data.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        if (close) {
          this.loading = false;
        }
        console.log(res);
        if (res.status < 400) {
          if (close) {
            this.close('save');
          }
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        if (close) {
          this.loading = false;
        }
        this.snackSvc.openSnackBar('ERROR. Updating tour settings');
        console.log(error);
      });
  }

  onEditRoute() {
    this.dialogRef.close();
    this.router.navigate(['itinerary', this.data.tour.prodid]);
  }

  // Restore given tour
  onRegenerateTour() {
    this.loading = true;
    // Show snackbar to undo restore
    const snackbarRef = this.snackSvc.openSnackBar('Restore everything?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          // If dismiss or no action with snackbar (undo) delete the tour folder
          this.httpTour.regenerateTour(this.data.tour.id, 2, true, false)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                this.close('reg');
                this.loading = false;
              },
              error => {
                console.log(error);
                this.loading = false;
                this.snackSvc.openSnackBar('ERROR. Regenerating tour');
              });
        } else {
          this.loading = false;
        }
      });
  }

  // ITINERARY

  selectCity(it) {
    this.hotelsCity = this.data.hotels.filter(h => '' + h.city_id === '' + it.city_id);
    this.hotelsCity.unshift(null);
    it.city = this.cities.find(c => '' + c.id === '' + it.city_id);
  }

  changeCity(event, comp) {
    comp.city_id = Number(event.value);
    comp.showCities = false;
    this.updateComponent(comp);
  }

  selectHotel(it) {
    it.hotel = this.data.hotels.find(h => '' + h.id === '' + it.hotel_id);
    it.hotel_name = it.hotel?.name;
  }

  onRewriteDays() {
    this.loading = true;
    this.httpItinerarySvc.reWriteDays(this.data.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.tour.itinerary.map(it => it.days = res.results.days.filter(d => '' + d.itinerary_id === '' + it.id));
            this.snackSvc.openSnackBar(res.message);
          } else {
            console.log('Error: days not rewritten');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Rewriting days');
        });
  }

  onRecreateSafetyChecks() {
    this.loading = true;
    this.httpItinerarySvc.recreateSafetyChecks(this.data.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar(res.message);
          } else {
            console.log('Error recreating safety checks');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Recreating safety checks');
        });
  }

  onRecreateFTs() {
    this.loading = true;
    this.httpItinerarySvc.recreateSFTs(this.data.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar(res.message);
          } else {
            console.log('Error recreating free time suggestions');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Recreating free time suggestions');
        });
  }

  onRecreateOthers() {
    this.loading = true;
    this.httpItinerarySvc.recreateOthers(this.data.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar(res.message);
          } else {
            console.log('Error recreating other components');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Recreating other components');
        });
  }

  onCopyItinerary() {
    const dialogRef = this.dialog.open(DupeItinComponent, {
      autoFocus: true,
      data: {'tour': this.data.tour, 'all_tours': this.data.tours}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] === 'save') {
        this.snackSvc.openSnackBar('Itinerary duplicated');
      } else {
        console.log('Didnt duplicate itinerary');
      }
    });
  }

  fakePayment(tour: Tour) {
    this.loading = true;
    const data = {
      'user_id': tour.user_id,
      'tour_id': tour.id,
      'amount': 0,
      'currency_code': 'EUR',
    };
    this.httpOrder.makeFakePay(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            tour.tour_settings.paid_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
            if (!this.idxEditOrd.includes(res.results.orders.id)) {
              this.data.tour.orders.push(res.results.orders);
              this.idxEditOrd = [res.results.orders.id];
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(tour.user_id, this.router.url, 'Error makeFakePay()', JSON.stringify(error));
        });
  }

  onAddIt() {
    const it = {
      'id': 0,
      'tour_id': '' + this.data.tour.id,
      'check_in': null,
      'hotel': null,
      'hotel_id': null,
      'city_id': null,
      'hotel_name': null,
      'travel_by': null,
      'flag': '',
      'check_out': null,
      'deleted_at': null
    };
    this.data.tour.itinerary.map(i => {
      i.show_days = false;
    });
    if (!this.idxEditItin.includes(it.id)) {
      this.data.tour.itinerary.push(it);
      this.idxEditItin = [it.id];
    }
  }

  onEditIt(it) {
    this.hotelsCity = this.data.hotels.filter(h => '' + h.city_id === '' + it.city_id);
    this.hotelsCity.unshift(null);
    this.data.tour.itinerary.map(i => {
      i.show_days = false;
    });
    this.idxEditDay = [];
    if (!this.idxEditItin.includes(it.id)) {
      this.idxEditItin.push(it.id);
    }
  }

  onCloseIt(itin) {
    if (itin.id === 0) {
      const remIdx = this.data.tour.itinerary.findIndex(i => '' + i === '0');
      this.data.tour.itinerary.splice(remIdx, 1);
    }
    const removeIdx = this.idxEditItin.findIndex(i => '' + i === '' + itin.id);
    this.idxEditItin.splice(removeIdx, 1);
  }

  onUpdateIt(itin, type?: string) {
    let data;
    if (itin.id !== 0) {
      if (type && type === 'restore') {
        itin.deleted_at = null;
        data = {
          'restore': true,
        };
      } else {
        data = {
          'tour_id': this.data.tour.id,
          'city_id': itin.city.id,
          'hotel_id': itin.hotel ? itin.hotel.id : null,
          'hotel_name': itin.hotel ? itin.hotel.name : null,
          'check_in': itin.check_in,
          'check_out': itin.check_out,
          'travel_by': '' + itin.travel_by,
        };
      }
      // console.log(data);
      this.httpItinerarySvc.updateItineraryTour(itin.id, data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              itin = res.results.itinerary;
            } else {
              if (!this.idxEditItin.includes(itin.id)) {
                this.idxEditItin.push(itin.id);
              }
              console.log('Error: itinerary not updated');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            if (!this.idxEditItin.includes(itin.id)) {
              this.idxEditItin.push(itin.id);
            }
            this.snackSvc.openSnackBar('ERROR. Updating itinerary');
          });

    } else {
      if (!itin.city) {
        this.snackSvc.openSnackBar('ERROR. City missing');
        return;
      }
      // if (!itin.hotel) {
      //   this.snackSvc.openSnackBar('ERROR. Hotel missing');
      //   return;
      // }
      if (!itin.check_in) {
        this.snackSvc.openSnackBar('ERROR. Check-in missing');
        return;
      }
      if (!itin.check_out) {
        this.snackSvc.openSnackBar('ERROR. Check-out missing');
        return;
      }
      if (!itin.travel_by) {
        this.snackSvc.openSnackBar('ERROR. Travel by missing');
        return;
      }
      data = {
        'tour_id': this.data.tour.id,
        'city_id': itin.city.id,
        'hotel_id': itin.hotel ? itin.hotel.id : null,
        'hotel_name': itin.hotel ? itin.hotel.name : null,
        'check_in': itin.check_in,
        'check_out': itin.check_out,
        'travel_by': '' + itin.travel_by,
      };
      // console.log(data);
      this.httpItinerarySvc.addItineraryTour(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.onCloseIt(itin);
              this.data.tour.itinerary.push(res.results.itin);
            } else {
              if (!this.idxEditItin.includes(itin.id)) {
                this.idxEditItin.push(itin.id);
              }
              console.log('Error: itinerary not created');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            if (!this.idxEditItin.includes(itin.id)) {
              this.idxEditItin.push(itin.id);
            }
            this.snackSvc.openSnackBar('ERROR. Creating itinerary');
          });
    }
    const removeIdx = this.idxEditItin.findIndex(i => '' + i === '' + itin.id);
    this.idxEditItin.splice(removeIdx, 1);
  }

  actuallyDeleteItin(itin) {
    itin.deleted_at = true;
    this.httpItinerarySvc.deleteItineraryTour(itin.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // console.log('ok');
          } else {
            itin.deleted_at = null;
            // Error deleting city itinerary in DB
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          itin.deleted_at = null;
          this.snackSvc.openSnackBar('ERROR. Deleting itinerary');
          console.log(error);
        });
    const removeIdx = this.idxEditItin.findIndex(i => '' + i === '' + itin.id);
    this.idxEditItin.splice(removeIdx, 1);
  }

  actuallyForceDeleteItin(itin) {
    this.httpItinerarySvc.deleteItineraryTour(itin.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const removeIdx2 = this.data.tour.itinerary.findIndex(i => '' + i.id === '' + itin.id);
            this.data.tour.itinerary.splice(removeIdx2, 1);
          } else {
            // Error deleting city itinerary in DB
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.snackSvc.openSnackBar('ERROR. Force deleting itinerary');
          console.log(error);
        });
    const removeIdx = this.idxEditItin.findIndex(i => '' + i === '' + itin.id);
    this.idxEditItin.splice(removeIdx, 1);
  }

  onDeleteIt(itin: Itinerary) {
    if (itin.deleted_at) {
      if (itin.components_count > 0) {
        // Show snackbar to undo force delete
        const snackbarRef = this.snackSvc.openSnackBar('WARN: ' + itin.components_count + ' comps!', 'Del (F)');
        snackbarRef.afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(reason => {
            if (reason.dismissedByAction) {
              this.actuallyForceDeleteItin(itin);
            }
          });
      } else {
        this.actuallyForceDeleteItin(itin);
      }
    } else {
      if (itin.components_count > 0) {
        // Show snackbar to undo delete
        const snackbarRef = this.snackSvc.openSnackBar('WARN: ' + itin.components_count + ' comps!', 'Del');
        snackbarRef.afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(reason => {
            if (reason.dismissedByAction) {
              this.actuallyDeleteItin(itin);
            }
          });
      } else {
        this.actuallyDeleteItin(itin);
      }
    }
  }

  // DAYS

  onAddDay(itin) {
    const day = {
      'id': 0,
      'itinerary_id': itin.id,
      'name': null,
      'nday': null,
      'date': null,
      'deleted_at': null
    };
    this.data.tour.itinerary.map(i => (i !== itin) ? i.show_days = false : null);
    if (!this.idxEditDay.includes(day.id)) {
      itin.days.push(day);
      this.idxEditDay.push(day.id);
    }
  }

  onEditDay(day, it_id?: number) {
    this.data.tour.itinerary.map(i => (i.id !== it_id) ? i.show_days = false : null);
    this.idxEditDay = [];
    if (!this.idxEditDay.includes(day.id)) {
      this.idxEditDay.push(day.id);
    }
  }

  onCloseDay(day, it_id) {
    if (day.id === 0) {
      const itIdx = this.data.tour.itinerary.findIndex(i => '' + i.id === '' + it_id);
      const remIdx = this.data.tour.itinerary[itIdx].days.findIndex(i => '' + i === '0');
      this.data.tour.itinerary[itIdx].days.splice(remIdx, 1);
    }
    const removeIdx = this.idxEditDay.findIndex(i => '' + i === '' + day.id);
    this.idxEditDay.splice(removeIdx, 1);
  }

  onUpdateDay(day: Day, it_id, type?: string) {
    let data;
    if (day.id !== 0) {
      if (type && type === 'restore') {
        data = {
          'restore': true,
        };
        day.deleted_at = null;
      } else {
        data = {
          'req_id': 2,
          'name': day.name,
          'date': day.date,
          'nday': day.nday,
          'itinerary_id': day.itinerary_id,
        };
      }
      this.httpItinerarySvc.updateDay(day.id, data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status === 203) {
              day = res.results.day;
            } else {
              this.onEditDay(day);
              console.log('Error: day not updated');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            this.onEditDay(day);
            console.log(error);
            this.snackSvc.openSnackBar('Error updating day');
          });
    } else {
      if (!day.name) {
        this.snackSvc.openSnackBar('ERROR. Day name missing');
        return;
      }
      if (!day.date) {
        this.snackSvc.openSnackBar('ERROR. Day date missing');
        return;
      }
      if (!day.nday) {
        this.snackSvc.openSnackBar('ERROR. #Day missing');
        return;
      }
      data = {
        'itinerary_id': it_id,
        'name': day.name,
        'date': day.date,
        'nday': day.nday,
      };
      this.httpItinerarySvc.createDay(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.onCloseDay(day, it_id);
              this.data.tour.itinerary.find(i => '' + i.id === '' + it_id).days.push(res.results.day);
            } else {
              console.log('Error: day not created');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            this.snackSvc.openSnackBar('Error creating day');
          });
    }
    const removeIdx = this.idxEditDay.findIndex(i => '' + i === '' + day.id);
    this.idxEditDay.splice(removeIdx, 1);
  }

  actuallyDeleteDay(day) {
    day.deleted_at = true;
    this.httpItinerarySvc.deleteDay(day.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // console.log('ok');
          } else {
            day.deleted_at = null;
            // Error deleting city itinerary in DB
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          day.deleted_at = null;
          this.snackSvc.openSnackBar('ERROR. Deleting day');
          console.log(error);
        });
    const removeIdx = this.idxEditDay.findIndex(i => '' + i === '' + day.id);
    this.idxEditDay.splice(removeIdx, 1);
  }

  actuallyForceDeleteDay(it, day) {
    this.httpItinerarySvc.deleteDay(day.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const removeIdx2 = it.days.findIndex(i => '' + i.id === '' + day.id);
            it.days.splice(removeIdx2, 1);
          } else {
            // Error deleting city itinerary in DB
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.snackSvc.openSnackBar('ERROR. Force deleting day');
          console.log(error);
        });
    const removeIdx = this.idxEditDay.findIndex(i => '' + i === '' + day.id);
    this.idxEditDay.splice(removeIdx, 1);
  }

  onDeleteDay(it, day) {
    if (day.deleted_at) {
      if (day.events_count > 0) {
        // Show snackbar to undo force delete
        const snackbarRef = this.snackSvc.openSnackBar('WARN: ' + day.events_count + ' evts!', 'Del (F)');
        snackbarRef.afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(reason => {
            if (reason.dismissedByAction) {
              this.actuallyForceDeleteDay(it, day);
            }
          });
      } else {
        this.actuallyForceDeleteDay(it, day);
      }
    } else {
      if (day.events_count > 0) {
        // Show snackbar to undo delete
        const snackbarRef = this.snackSvc.openSnackBar('WARN: ' + day.events_count + ' evts!', 'Del');
        snackbarRef.afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(reason => {
            if (reason.dismissedByAction) {
              this.actuallyDeleteDay(day);
            }
          });
      } else {
        this.actuallyDeleteDay(day);
      }
    }
  }

  // PAX

  onSignUp() {
    if (!this.tour_opt_id) {
      this.snackSvc.openSnackBar('Set tour_opt id');
      return;
    }
    if (!this.user_id) {
      this.snackSvc.openSnackBar('Set user id');
      return;
    }
    const params = {
      'user_id': this.user_id,
      'tour_optional_id': this.tour_opt_id,
      'url': environment.selfUrl,
      'silent': 1,
    };
    // console.log(params);
    this.httpTOSvc.createTourOptPax(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.user_id = null;
            this.tour_opt_id = null;
            this.snackSvc.openSnackBar(res.message);
          } else {
            if (res.status === 405) {
              this.snackSvc.openSnackBar('Not allowed. Contact Tour leader');
            } else {
              this.snackSvc.openSnackBar('ERROR. Signing up');
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Signing up');
        });
  }

  onSignOut() {
    if (!this.tour_opt_id) {
      this.snackSvc.openSnackBar('Set tour_opt id');
      return;
    }
    if (!this.user_id) {
      this.snackSvc.openSnackBar('Set user id');
      return;
    }
    this.httpTOSvc.deleteTourOptPax(null, +this.tour_opt_id, 1, 1, +this.user_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.user_id = null;
            this.tour_opt_id = null;
            this.snackSvc.openSnackBar(res.message);
          } else {
            if (res.status === 405) {
              this.snackSvc.openSnackBar('Not allowed. Contact Tour leader');
            } else {
              this.snackSvc.openSnackBar('ERROR. Signing out');
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  onAddToTour(tour) {
    if (!this.user_id) {
      this.snackSvc.openSnackBar('Write a user id');
      return;
    }
    this.setts_loading = true;
    const data = {
      'tour_id': tour.id,
      'user_id': this.user_id,
      'silent': true,
    };
    // console.log(data);
    this.httpTour.createTourPax(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.user_id = null;
            this.tour_opt_id = null;
            if (!res.message.includes('has already')) {
              res.results.tour_pax.last_txt = this.getLastTxt(res.results.tour_pax.updated_at);
              tour.tour_pax.push(res.results.tour_pax);
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.setts_loading = false;
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error adding user to tour');
        });
  }

  onCreateAccess() {
    const data = {
      'req_id': this.data.tour.user_id,
      'tour_id': this.data.tour.id,
      'user_id': this.user_id2,
      'verified_at': this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES'),
      'url': environment.selfUrl,
    };
    // console.log(data);
    this.httpTour.createAccess(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = this.data.tour.tour_access.findIndex(t => +t.id === +res.results.tour_access.id);
            if (idx < 0) {
              this.data.tour.tour_access.push(res.results.tour_access);
            }
            this.user_id2 = null;
            this.error = null;
          } else {
            if (res.results) {
              this.error = res.results[Object.keys(res.results)[0]].toString();
            } else {
              this.error = res.message.toString();
            }
          }
        },
        error => {
          console.log(error);
          this.error = error;
          this.snackSvc.openSnackBar('Error creating access');
        });
  }

  onUpdateAccess(ta) {
    if (ta.verified_at) {
      return;
    }
    const data = {
      'req_id': this.data.tour.user_id,
      'tour_id': ta.tour_id,
      'user_id': ta.user_id,
      'verified_at': this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES'),
      'url': environment.selfUrl,
    };
    // console.log(data);
    this.httpTour.updateAccess(ta.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = this.data.tour.tour_access.findIndex(t => +t.id === +res.results.tour_access.id);
            if (idx >= 0) {
              this.data.tour.tour_access[idx] = res.results.tour_access;
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error creating access');
        });
  }

  deleteTourAccess(ta) {
    this.httpTour.deleteAccess(ta.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.tour.tour_access = this.data.tour.tour_access.filter(t => t.id !== ta.id);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error deleting access');
        });
  }

  onUpdateUser(user, type) {
    const params = {
      'req_id': 2,
    };
    if (type === 'role') {
      params['role'] = user.role === 1 ? 2 : 1;
    } else if (type === 'gender') {
      if (user.gender === 'male' || user.gender === '?') {
        params['gender'] = 'female';
      } else if (user.gender === 'female') {
        params['gender'] = 'x';
      } else if (user.gender === 'x') {
        params['gender'] = 'male';
      }
    }
    // console.log(params);
    this.httpUser.updateUser(user.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          user[type] = res.results.user[type];
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Updating user');
      });
  }

  onUpdateTourPax(tour_pax: TourPax, type: string) {
    const params = {
      'req_id': 2,
    };
    if (type === 'rooms') {
      if (this.data.tour.company_id === 3) {
        if (tour_pax.room === 'Si') {
          params['room'] = 'Tw';
        } else if (tour_pax.room === 'Tw') {
          params['room'] = 'Db';
        } else if (tour_pax.room === 'Db') {
          params['room'] = 'Tr';
        } else if (tour_pax.room === 'Tr') {
          params['room'] = 'Qd';
        } else if (tour_pax.room === 'Tr' || tour_pax.room === '?') {
          params['room'] = 'Si';
        }
      } else {
        if (tour_pax.room === 'Si') {
          params['room'] = 'Tw';
        } else if (tour_pax.room === 'Tw') {
          params['room'] = 'Db';
        } else if (tour_pax.room === 'Db' || tour_pax.room === '?') {
          params['room'] = 'Si';
        }
      }
    } else if (type === 'active') {
      params['active'] = !tour_pax.active;
    } else if (type === 'hidden') {
      params['hidden'] = !tour_pax.hidden;
    }
    // console.log(params);
    this.httpTour.updateTourPax(tour_pax.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            tour_pax[type] = res.results.tp[type];
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating tour pax');
        });
  }

  onUserSettsChange(user) {
    const params = {
      'show_ads': !user.user_settings.show_ads,
    };
    // console.log(params);
    this.httpUser.updateUserSettings(user.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            user.user_settings = res.results.user_settings;
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating settings');
        });
  }

  onRemoveFromTour(tp) {
    this.setts_loading = true;
    this.httpTour.deleteTourPax(tp.id, 2)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Remove tourpax to data
            const idx = this.data.tour.tour_pax.findIndex(tpp => tpp.id === tp.id);
            this.data.tour.tour_pax.splice(idx, 1);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
              console.log(res.message.toString());
            }
          }
          this.setts_loading = false;
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error removing user from tour');
        });
  }

  getLastTxt(updated_at): string {
    if (!updated_at) {
      return '';
    }
    let time_amount;
    let time_units;
    const startDate = new Date(updated_at);
    const endDate = new Date();
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 's';
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'm';
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'h';
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'd';
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'y';
    }
    return time_amount + time_units;
  }

  // OPTIONALS
  togglePaxControl(topt: TourOptional) {
    if (topt.status === 'open') {
      topt.status = 'block';
    } else if (topt.status === 'block') {
      topt.status = 'stop';
    } else if (topt.status === 'stop') {
      topt.status = 'freeze';
    } else if (topt.status === 'freeze') {
      topt.status = 'open';
    } else {
      topt.status = 'open';
    }
    this.updTourOpt(topt, 'status');
  }

  toggleOnlinePay(topt: TourOptional) {
    topt.pay_online = !topt.pay_online;
    this.updTourOpt(topt, 'pay_online');
  }

  updTourOpt(topt: TourOptional, txt?) {
    let data = {};
    if (!txt) {
      data = {
        'pay_online': topt.pay_online,
        'status': topt.status,
      };
    } else {
      data[txt] = topt[txt];
    }
    // console.log(data);
    this.httpTOSvc.updateTourOptional(topt.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            topt.status = res.results.tour_optional.status;
            topt.pay_online = res.results.tour_optional.pay_online !== 0;
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  onDeleteTourOpt(topt: TourOptional) {
    const snackbarRef = this.snackSvc.openSnackBar('Delete tour optional?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.httpTOSvc.deleteTourOptional(2, topt.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  // Remove tour optional
                  const remIdx = this.data.tour.tour_optionals.findIndex(i => '' + i.id === '' + topt.id);
                  this.data.tour.tour_optionals.splice(remIdx, 1);
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    this.snackSvc.openSnackBar(res.message.toString());
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('Error deleting optional');
              });
        }
      });
  }

  // ORDERS

  onAddOrd() {
    const ord = {
      'id': 0,
      'user_id': this.data.tour.user_id,
      'reference': null,
      't_name': null,
      't_idcode': this.data.tour.prodid + '#' + this.data.tour.code,
      't_logo': null,
      't_plan': null,
      'amount': 0,
      'currency_code': 'EUR',
      'voucher': null,
      'str_fee': 0,
      'pln_fee': 0,
      'status': 'Complete',
      'notes': null,
      'created_at': null,
      'deleted_at': null,
      'paypal_id': null,
      'stripe_id': null,
    };
    this.data.tour.itinerary.map(i => {
      i.show_days = false;
    });
    if (!this.idxEditOrd.includes(ord.id)) {
      this.data.tour.orders.push(ord);
      this.idxEditOrd = [ord.id];
    }
  }

  onEditOrd(ord) {
    if (!this.idxEditOrd.includes(ord.id)) {
      this.idxEditOrd.push(ord.id);
    }
  }

  onCloseOrd(ord) {
    if (ord.id === 0) {
      const remIdx = this.data.tour.orders.findIndex(i => '' + i === '' + ord.id);
      this.data.tour.orders.splice(remIdx, 1);
    }
    const removeIdx = this.idxEditOrd.findIndex(i => '' + i === '' + ord.id);
    this.idxEditOrd.splice(removeIdx, 1);
  }

  onUpdateOrd(ord, type?: string) {
    let data;
    if (ord.id !== 0) {
      if (type && type === 'restore') {
        ord.deleted_at = null;
        data = {
          'restore': true,
        };
      } else {
        data = {
          'req_id': 2,
          'user_id': ord.user_id,
          'reference': ord.reference,
          't_name': this.data.tour.name,
          't_idcode': ord.t_idcode,
          't_logo': ord.t_logo,
          't_plan': ord.t_plan,
          'amount': ord.amount,
          'currency_code': ord.currency_code,
          'voucher': ord.voucher,
          'status': ord.status,
        };
        if (ord.str_fee !== null) {
          data['str_fee'] = ord.str_fee === 0 ? '0' : ord.str_fee;
        }
        if (ord.str_fee !== null) {
          data['pln_fee'] = ord.pln_fee === 0 ? '0' : ord.pln_fee;
        }
      }
      // console.log(data);
      this.httpOrder.updateOrder(ord.id, data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              ord = res.results.orders;
            } else {
              if (!this.idxEditOrd.includes(ord.id)) {
                this.idxEditOrd.push(ord.id);
              }
              console.log('Error: order not updated');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            if (!this.idxEditOrd.includes(ord.id)) {
              this.idxEditOrd.push(ord.id);
            }
            this.snackSvc.openSnackBar('ERROR. Updating order');
          });

    } else {
      if (ord.amount === null) {
        this.snackSvc.openSnackBar('ERROR. Amount missing');
        return;
      }
      if (ord.str_fee === null) {
        this.snackSvc.openSnackBar('ERROR. STR missing');
        return;
      }
      if (ord.pln_fee === null) {
        this.snackSvc.openSnackBar('ERROR. PLN missing');
        return;
      }
      if (!ord.currency_code) {
        this.snackSvc.openSnackBar('ERROR. Currency code missing');
        return;
      }
      data = {
        'tour_id': this.data.tour.id,
        'user_id': this.data.tour.user_id,
        'reference': ord.reference,
        't_name': ord.t_name,
        't_idcode': ord.t_idcode,
        't_logo': ord.t_logo,
        't_plan': ord.t_plan,
        'amount': ord.amount,
        'pln_fee': ord.pln_fee,
        'str_fee': ord.str_fee,
        'currency_code': ord.currency_code,
        'voucher': ord.voucher,
        'status': ord.status,
      };
      // console.log(data);
      this.httpOrder.addOrder(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.onCloseOrd(ord);
              this.data.tour.orders.push(res.results.orders);
            } else {
              if (!this.idxEditOrd.includes(ord.id)) {
                this.idxEditOrd.push(ord.id);
              }
              console.log('Error: order not created');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            if (!this.idxEditOrd.includes(ord.id)) {
              this.idxEditOrd.push(ord.id);
            }
            this.snackSvc.openSnackBar('ERROR. Creating order');
          });
    }
    const remIdx = this.idxEditOrd.findIndex(i => '' + i === '' + ord.id);
    this.idxEditOrd.splice(remIdx, 1);
  }

  onDeleteOrd(ord) {
    if (ord.deleted_at) {
      this.httpOrder.deleteOrder(ord.id, 'true')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              const removeIdx2 = this.data.tour.orders.findIndex(i => '' + i.id === '' + ord.id);
              this.data.tour.orders.splice(removeIdx2, 1);
            } else {
              // Error deleting city itinerary in DB
              if (res.results) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              } else {
                console.log(res.message.toString());
              }
            }
          },
          error => {
            this.snackSvc.openSnackBar('ERROR. Force deleting order');
            console.log(error);
          });
    } else {
      ord.deleted_at = true;
      this.httpOrder.deleteOrder(ord.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              // console.log('ok');
            } else {
              ord.deleted_at = null;
              // Error deleting city itinerary in DB
              if (res.results) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              } else {
                console.log(res.message.toString());
              }
            }
          },
          error => {
            ord.deleted_at = null;
            this.snackSvc.openSnackBar('ERROR. Deleting order');
            console.log(error);
          });
    }
    const removeIdx = this.idxEditOrd.findIndex(i => '' + i === '' + ord.id);
    this.idxEditOrd.splice(removeIdx, 1);
  }

  createVoucher(user) {
    const dialogRef = this.dialog.open(AddVoucherComponent, {
      autoFocus: true,
      data: {user: user}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        user.voucher.push(result['voucher']);
      }
    });
  }

  // COMPONENT

  updateComponent(comp) {
    const data = {
      'city_id': comp.city_id
    };
    // console.log(data);
    this.httpComp.updateComponent(comp.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            comp = res.results.component;
            const idx = this.untour_components.findIndex(c => c.id === comp.id);
            if (idx >= 0) {
              this.untour_components[idx] = comp;
            }
            const idx2 = this.uncity_components.findIndex(c => c.id === comp.id);
            if (idx2 >= 0) {
              this.uncity_components[idx2] = comp;
            }
            // And filter
            const cities_id = this.data.tour.itinerary.map((i) => i.city_id);
            this.untour_components = this.untour_components.filter(c => !cities_id.includes(c.city_id));
            this.uncity_components = this.uncity_components.filter(c => !cities_id.includes(c.city_id));
          } else {
            console.log('Error: component not updated');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating component');
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

}
