import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import faqs_json from '../../../../../assets/json/faqs.json';
import {TitleService} from '../../../../shared/services/common/title.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import packageJson from '../../../../../../package.json';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit, OnDestroy {
  public embedHeight: any;
  public embedWidth: any;
  public searchText: string;
  public loading = false;
  public searching = false;
  public version: string = packageJson.version;
  public faq: any;
  public faqs: any = [];
  public answers: { id: number, question: string, slug: string, answer: string, open: boolean }[] = [];
  public vids: { desc: string, url: any }[] = [];

  @ViewChildren('q_target') searchElementMsgsRefs: QueryList<ElementRef>;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private titleSvc: TitleService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {
    this.titleSvc.setTitle('Support');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.embedHeight = window.innerWidth < 576 ? 350 : 150;
    this.embedWidth = window.innerWidth < 576 ? 333 : 250;
  }

  cleanUrl(id) {
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + id);
  }

  ngOnInit() {
    this.onResize();
    this.vids.push({desc: 'Intro (1:53)', url: this.cleanUrl('ALq948STMuk')});
    this.vids.push({desc: 'Create first tour (7:12)', url: this.cleanUrl('H280MR59Ln4')});
    this.vids.push({desc: 'Self-written emails (12:54)', url: this.cleanUrl('BqXfsBrQDEI')});
    this.vids.push({desc: 'Automatic rooming [brief] (4:16)', url: this.cleanUrl('Z9w_p89Kxfk')});
    this.vids.push({desc: 'Automatic rooming (11:26)', url: this.cleanUrl('qd5Z1Pu1aak')});
    for (let i = 0; i < faqs_json.length; i++) {
      this.faqs.push(faqs_json[i]['question']);
      this.answers.push(faqs_json[i]);
    }
    this.faq = this.route.snapshot.params['faq'];
    if (this.faq) {
      const item = this.answers.find(it => '' + it.slug === '' + this.faq);
      setTimeout(() => {
        this.onSelectItem(item);
      }, 1);
    }
  }

  public searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  onSelectQ(q) {
    this.searchText = q;
    this.searching = false;
    this.answers.map(it => it.open = false);
    this.answers.find(it => '' + it.question === '' + q).open = true;
    const id = this.answers.find(it => '' + it.question === '' + q).id;
    const idx = this.searchElementMsgsRefs.toArray().findIndex(it => '' + it.nativeElement.id === '' + id);
    if (idx >= 0) {
      setTimeout(() => {
        this.searchElementMsgsRefs.toArray()[idx].nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 1);
    }
  }

  onSelectItem(item) {
    this.searching = false;
    const idx = this.searchElementMsgsRefs.toArray().findIndex(it => '' + it.nativeElement.id === '' + item.id);
    if (idx >= 0) {
      setTimeout(() => {
        this.searchElementMsgsRefs.toArray()[idx].nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 1);
    }
    this.answers.find(it => it.id === item.id).open = !this.answers.find(it => '' + it.id === '' + item.id).open;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
