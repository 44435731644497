import {Component, OnDestroy, OnInit} from '@angular/core';
import {Tour} from '../../../../shared/models/tour.model';
import {User} from '../../../../shared/models/user.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {EditTourComponent} from '../../../components/admin/edit-tour/edit-tour.component';
import {Hotel} from '../../../../shared/models/hotel.model';
import {DupeTourComponent} from '../../../components/tour/dupe-tour/dupe-tour.component';
import {DatePipe} from '@angular/common';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {OutlookService} from '../../../../shared/services/session/outlook.service';
import {Company} from '../../../../shared/models/company.model';

@Component({
  selector: 'app-adm-tours',
  templateUrl: './adm-tours.component.html',
  styleUrls: ['./adm-tours.component.scss']
})
export class AdmToursComponent implements OnInit, OnDestroy {
  currentUser: User;
  tours: Tour[] = [];
  toursFiltered: Tour[] = [];
  tds: User[] = [];
  hotels: Hotel[] = [];
  tourPaxs: number;
  loading: boolean;
  searchText: string;
  searching = false;
  showSearch = false;
  filter: string;
  sortBy: string;
  companies: Company[] = [];
  today: string;
  role: string;
  ok: boolean;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private textTransform: TextTransformService,
    private httpUser: HttpUserService,
    private outlookSvc: OutlookService,
    private httpTour: HttpTourService,
    private httpOrders: HttpOrdersService,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit(): void {
    this.today = this.textTransform.addDays(new Date(), 0);
    this.companies = JSON.parse(localStorage.getItem('companies'));
    const list2 = '.1.2.3.4.5.6.';
    this.companies = this.companies.filter(c => list2.includes('.' + c.id + '.'));
    this.getAllTours();
  }

  printTours() {
    console.log(this.toursFiltered);
  }

  onViewAs(user_id) {
    this.httpTour.getToursUser(user_id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            localStorage.setItem('cities', JSON.stringify(res.results.cities));
            localStorage.setItem('compTypes', JSON.stringify(res.results.comp_types));
            localStorage.setItem('gssTypes', JSON.stringify(res.results.gss_types));
            localStorage.setItem('companies', JSON.stringify(res.results.companies));
            this.activeTourSvc.setToursUser(res.results.tours);
            this.currentUser.view_as = user_id;
            this.authSvc.updateUser(this.currentUser);
            this.router.navigate(['tours'], {queryParams: {viewAs: user_id}});
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.snackSvc.openSnackBar('ERROR View as');
          console.log(error);
        });
  }

  getAllTours() {
    this.loading = true;
    this.httpTour.getAllTours()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.tours = res.results.tour;
            this.toursFiltered = this.tours;
            this.toursFiltered.map(d => {
              if (this.today >= this.textTransform.addDays(new Date(d.tour_starts), 0) &&
                this.today < this.textTransform.addDays(new Date(d.tour_starts), d.ndays)) {
                d.state = 'ON';
              } else if (this.today < this.textTransform.addDays(new Date(d.tour_starts), 0)) {
                d.state = 'Coming';
              } else if (this.today >= this.textTransform.addDays(new Date(d.tour_starts), d.ndays)) {
                d.state = 'past';
              }
              d.dayTour = this.textTransform.dateDiffInDays(new Date(d.tour_starts), new Date()) + 1;
            });
            this.tourPaxs = this.toursFiltered.reduce((acc, tour) => acc + tour.tour_pax_count, 0);
            this.tds = res.results.tds;
            this.hotels = res.results.hotels;
            const old_f = this.filter;
            this.filter = null;
            if (old_f) {
              this.filterBy(old_f);
            }
            const old_s = this.sortBy;
            this.sortBy = null;
            this.orderBy(old_s ? old_s : 'updated_at');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error getting tours');
        });
  }

  onNewTour() {
    // Create tour
    const params = {
      'user_id': this.currentUser.id,
    };
    this.httpTour.createTour(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const tour = res.results.tour;
            tour.user = this.currentUser;
            tour.itinerary = [];
            tour.orders = [];
            this.toursFiltered.push(tour);
            this.onEditTour(res.results.tour);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  onEditTour(tour: Tour) {
    if (tour.deleted_at) {
      this.snackSvc.openSnackBar('Restore tour to edit');
      return;
    }
    const dialogRef = this.dialog.open(EditTourComponent, {
      maxWidth: '90vw',
      minHeight: '90vh',
      width: '1024px',
      data: {'tour': tour, 'tours': this.tours.filter(h => h.state === 'Coming'), 'tds': this.tds, 'hotels': this.hotels}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result.reason !== 'close') {
        result.tour.user = result.tds.find(t => t.id === result.tour.user_id);
        const tIdx = this.toursFiltered.findIndex(t => t.id === result.tour.id);
        this.toursFiltered[tIdx] = result.tour;
        if (this.today >= this.textTransform.addDays(new Date(this.toursFiltered[tIdx].tour_starts), 0) &&
          this.today < this.textTransform.addDays(new Date(this.toursFiltered[tIdx].tour_starts), this.toursFiltered[tIdx].ndays)) {
          this.toursFiltered[tIdx].state = 'ON';
        } else if (this.today < this.textTransform.addDays(new Date(this.toursFiltered[tIdx].tour_starts), 0)) {
          this.toursFiltered[tIdx].state = 'Coming';
        } else if (this.today >= this.textTransform.addDays(new Date(this.toursFiltered[tIdx].tour_starts), this.toursFiltered[tIdx].ndays)) {
          this.toursFiltered[tIdx].state = 'past';
        }
        this.toursFiltered[tIdx].dayTour = this.textTransform.dateDiffInDays(new Date(this.toursFiltered[tIdx].tour_starts), new Date()) + 1;
        this.snackSvc.openSnackBar('Tour updated');
        if (result.reason === 'reg') {
          this.getAllTours();
        }
        return;
      } else {
        // console.log('Didnt update tour');
      }
    });
  }

  onDeleteTour(tour) {
    this.httpTour.deleteTour(tour.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Get all tours
            tour.deleted_at = true;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error deleting tour');
        });
  }

  onForceDeleteTour(tour) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Force delete tour ' + tour.id + '!?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.forceDeleteTour(tour);
        }
      });
  }

  forceDeleteTour(tour) {
    this.httpTour.deleteTour(tour.id, this.currentUser.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Get all tours
            const idx = this.toursFiltered.findIndex(t => '' + t.id === '' + tour.id);
            this.toursFiltered.splice(idx, 1);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error force deleting tour');
        });
  }

  onRestoreTour(tour) {
    const params = {
      'req_id': this.currentUser.id,
      'restore': true,
    };
    this.httpTour.updateTour(tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          tour.deleted_at = null;
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Restoring tour');
      });
  }

  orderBy(txt, type?) {
    if (this.sortBy === txt) {
      this.sortBy = null;
      if (txt === 'tour_starts') {
        this.toursFiltered = this.tours;
      }
      if (type === 'desc') {
        this.toursFiltered.sort(function (a, b) {
          return b.updated_at.localeCompare(a.updated_at) || b.name.localeCompare(a.name);
        });
      } else {
        this.toursFiltered.sort(function (a, b) {
          return a.updated_at.localeCompare(b.updated_at) || a.name.localeCompare(b.name);
        });
      }
    } else {
      if (type === 'desc') {
        this.toursFiltered = this.toursFiltered.sort((a, b) => a[txt] > b[txt] ? 1 : a[txt] === b[txt] ? 0 : -1);
      } else {
        this.toursFiltered = this.toursFiltered.sort((a, b) => a[txt] < b[txt] ? 1 : a[txt] === b[txt] ? 0 : -1);
      }
      if (txt === 'tour_starts') {
        this.toursFiltered = this.toursFiltered.filter(h => h.state === 'Coming' || h.state === 'ON');
      }
      this.sortBy = txt;
    }
    this.tourPaxs = this.toursFiltered.reduce((acc, tour) => acc + tour.tour_pax_count, 0);
  }

  filterBy(role?) {
    if (this.filter === role) {
      this.filter = null;
      this.toursFiltered = this.tours;
    } else {
      if (role === 'deleted') {
        this.toursFiltered = this.tours.filter(h => h.deleted_at);
        this.filter = role;
      } else if (role === 'ON') {
        this.toursFiltered = this.tours.filter(h => h.state === 'ON');
        this.filter = role;
      } else if (role === 'Coming') {
        this.toursFiltered = this.tours.filter(h => h.state === 'Coming');
        this.filter = role;
      } else if (role === 'not_paid') {
        this.toursFiltered = this.tours.filter(h => !h.tour_settings.paid_at);
        this.filter = role;
      } else if (role === 'Planafy App') {
        this.toursFiltered = this.tours.filter(h => h.company_id === 1);
        this.filter = role;
      } else if (role === 'EF Ultimate Break') {
        this.toursFiltered = this.tours.filter(h => h.company_id === 2);
        this.filter = role;
      } else if (role === 'EF Educational Tours') {
        this.toursFiltered = this.tours.filter(h => h.company_id === 3);
        this.filter = role;
      } else if (role === 'EF Adventures') {
        this.toursFiltered = this.tours.filter(h => h.company_id === 4);
        this.filter = role;
      } else if (role === 'EF Gap Year') {
        this.toursFiltered = this.tours.filter(h => h.company_id === 5);
        this.filter = role;
      } else if (role === 'EF Go Ahead') {
        this.toursFiltered = this.tours.filter(h => h.company_id === 6);
        this.filter = role;
      } else {
        this.filter = null;
        this.toursFiltered = this.tours;
      }
    }
    this.tourPaxs = this.toursFiltered.reduce((acc, tour) => acc + tour.tour_pax_count, 0);
  }

  recentlyOnline(upd) {
    const min = 15;
    return new Date(upd).getTime() + min * 60 * 1000 >= new Date().getTime();
  }

  // Duplicate tour
  onDuplicateTour(tour) {
    const dialogRef = this.dialog.open(DupeTourComponent, {
      autoFocus: true,
      data: {'tour_id': tour.id, 'user_id': this.currentUser.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
